import { FiltersTypes } from '.';

const wine = [
  {
    type: FiltersTypes.options,
    name: 'Сорт винограда',
    filterName: 'grapes',
    getOptions: (currentFilters) => currentFilters.grapes,
  },
  {
    type: FiltersTypes.options,
    name: 'Содержание сахара',
    filterName: 'wine_sugar_content',
    getOptions: (currentFilters) => currentFilters.wineSugarContents,
  },
  {
    type: FiltersTypes.options,
    name: 'Цвет',
    filterName: 'wine_color_content',
    getOptions: (currentFilters) => currentFilters.wineColorContents,
  },
];

const champagne = [
  {
    type: FiltersTypes.options,
    name: 'Сорт винограда',
    filterName: 'grapes',
    getOptions: (currentFilters) => currentFilters.grapes,
  },
  {
    type: FiltersTypes.options,
    name: 'Содержание сахара',
    filterName: 'champagne_sugar_content',
    getOptions: (currentFilters) => currentFilters.champagneSugarContents,
  },
  {
    type: FiltersTypes.options,
    name: 'Цвет',
    filterName: 'champagne_color_content',
    getOptions: (currentFilters) => currentFilters.champagneColorContents,
  },
];

const beer = [
  {
    type: FiltersTypes.options,
    name: 'Цвет',
    filterName: 'beer_color',
    getOptions: (currentFilters) => currentFilters.beerColors,
  },
  {
    type: FiltersTypes.options,
    name: 'Очистка',
    filterName: 'beer_filtration',
    getOptions: (currentFilters) => currentFilters.beerFiltrations,
  },
];

const brandy = [
  {
    type: FiltersTypes.options,
    name: 'Класс бренди',
    filterName: 'brandy_class',
    getOptions: (currentFilters) => currentFilters.brandyClasses,
  },
];

const liquor = [
  {
    type: FiltersTypes.options,
    name: 'Вид',
    filterName: 'liquor_sub_type',
    getOptions: (currentFilters) => currentFilters.liquorSubTypes,
  },
];

const extraFilters = { wine, champagne, beer, brandy, liquor };

export const getExtraFilters = (type) => (extraFilters[type] ? extraFilters[type] : []);
