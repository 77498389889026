export const svgIcons = {
  basket: (fill) => (
    <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.19995 21.5333C6.73328 21.5333 5.54662 22.7333 5.54662 24.2C5.54662 25.6666 6.73328 26.8666 8.19995 26.8666C9.66662 26.8666 10.8666 25.6666 10.8666 24.2C10.8666 22.7333 9.66662 21.5333 8.19995 21.5333ZM0.199951 0.199951V2.86662H2.86662L7.66662 12.9866L5.86662 16.2533C5.65328 16.6266 5.53328 17.0666 5.53328 17.5333C5.53328 19 6.73328 20.2 8.19995 20.2H24.1999V17.5333H8.75995C8.57328 17.5333 8.42662 17.3866 8.42662 17.2L8.46662 17.04L9.66662 14.8666H19.6C20.6 14.8666 21.4799 14.32 21.9333 13.4933L26.7066 4.83995C26.8133 4.65329 26.8666 4.42662 26.8666 4.19995C26.8666 3.46662 26.2666 2.86662 25.5333 2.86662H5.81328L4.55995 0.199951H0.199951ZM21.5333 21.5333C20.0666 21.5333 18.8799 22.7333 18.8799 24.2C18.8799 25.6666 20.0666 26.8666 21.5333 26.8666C23 26.8666 24.1999 25.6666 24.1999 24.2C24.1999 22.7333 23 21.5333 21.5333 21.5333Z"
        fill={fill || 'url(#basketGradient)'}
      />
      <defs>
        <linearGradient
          id="basketGradient"
          x1="13.5333"
          y1="0.199951"
          x2="13.5333"
          y2="26.8666"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0062A9" />
          <stop offset="1" stopColor="#0485E2" />
        </linearGradient>
      </defs>
    </svg>
  ),
  'header__logo--mobile': () => (
    <svg
      width="210"
      height="57"
      viewBox="0 0 210 57"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.0187 17.5326H17.5326C22.3332 17.5326 26.5077 19.2024 29.8472 22.5419C33.1868 25.8815 35.0653 30.0559 35.0653 34.8565C35.0653 39.6571 33.3955 43.8316 29.8472 47.1711C26.5077 50.5107 22.3332 52.1805 17.5326 52.1805H0V2.50464H30.056V12.5233H10.0187V17.5326ZM10.0187 42.5792H17.5326C19.6199 42.5792 21.2896 41.7444 22.7507 40.2833C24.2117 38.8222 25.0466 36.9438 25.0466 35.0653C25.0466 33.1868 24.2117 31.3083 22.7507 29.8472C21.2896 28.3862 19.4111 27.5513 17.5326 27.5513H10.0187V42.5792Z"
        fill="white"
      />
      <path
        d="M67.4173 2.50464V12.5233H47.38V22.5419H62.408V32.5606H47.38V42.5792H67.4173V52.5979H37.3613V2.50464H67.4173Z"
        fill="white"
      />
      <path
        d="M69.9219 2.50464H87.4545C92.2551 2.50464 96.4296 4.17441 99.7691 7.51396C103.109 10.8535 104.987 15.028 104.987 19.8286C104.987 24.6292 103.317 28.8036 99.7691 32.1431C96.4296 35.4827 92.2551 37.1525 87.4545 37.1525H79.9405V52.1805H69.9219V2.50464ZM92.6726 14.8192C91.2115 13.3582 89.5417 12.5233 87.4545 12.5233H79.9405V27.5513H87.4545C89.5417 27.5513 91.2115 26.7164 92.6726 25.2553C94.1336 23.7943 94.9685 21.9158 94.9685 20.0373C94.9685 18.1588 94.1336 16.2803 92.6726 14.8192Z"
        fill="white"
      />
      <path
        d="M147.358 1.46094V52.3891H137.339V24.8378L107.283 53.4327V2.50455H117.302V30.2646L147.358 1.46094Z"
        fill="white"
      />
      <path
        d="M181.875 56.25C197.408 56.25 210 43.658 210 28.125C210 12.592 197.408 0 181.875 0C166.342 0 153.75 12.592 153.75 28.125C153.75 43.658 166.342 56.25 181.875 56.25Z"
        fill="white"
      />
      <path
        d="M165.099 24.8374H167.813C169.482 24.8374 170.944 25.4636 172.196 26.7159C173.448 27.9683 174.074 29.4293 174.074 31.0991C174.074 32.7689 173.448 34.2299 172.196 35.4823C170.944 36.7346 169.482 37.3607 167.813 37.3607H161.76V19.6194H172.405V22.9589H165.308V24.8374H165.099ZM165.099 33.6038H167.813C168.439 33.6038 169.065 33.395 169.691 32.7689C170.109 32.3514 170.526 31.7253 170.526 30.8904C170.526 30.0555 170.317 29.638 169.691 29.0119C169.065 28.3857 168.648 28.177 167.813 28.177H165.099V33.6038Z"
        fill="#026DB0"
      />
      <path
        d="M182.214 19.2021L190.146 37.1522H186.389L185.136 34.4388H179.084L177.831 37.1522H174.074L182.214 19.2021ZM183.675 31.308L182.006 27.551L180.545 31.308H183.675Z"
        fill="#026DB0"
      />
      <path
        d="M190.98 19.6194H197.033C198.703 19.6194 200.164 20.2456 201.416 21.4979C202.669 22.7502 203.295 24.2113 203.295 25.881C203.295 27.5508 202.669 29.0119 201.416 30.2642C200.164 31.5165 198.703 32.1427 197.033 32.1427H194.32V37.3607H190.771V19.6194H190.98ZM199.12 23.7938C198.703 23.1677 198.077 22.9589 197.242 22.9589H194.528V28.177H197.242C197.868 28.177 198.494 27.9683 199.12 27.3421C199.538 26.9247 199.955 26.2985 199.955 25.4636C199.955 24.6287 199.538 24.42 199.12 23.7938Z"
        fill="#026DB0"
      />
    </svg>
  ),
  'header__open-menu-btn': () => (
    <svg width="21" height="14" viewBox="0 0 21 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="21" height="2.07237" rx="1.03618" fill="white" />
      <rect y="5.52612" width="21" height="2.07237" rx="1.03618" fill="white" />
      <rect y="11.0527" width="21" height="2.07237" rx="1.03618" fill="white" />
    </svg>
  ),
  'header__close-menu-btn': (fill) => (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M43.9659 37.2708L10.3548 3.65974C8.49628 1.80121 5.48797 1.81696 3.64519 3.65974C1.78666 5.51827 1.80241 8.52658 3.64519 10.3694L37.2405 43.9647C39.0991 45.8232 42.1074 45.8075 43.9502 43.9647C45.7929 42.1219 45.8087 39.1136 43.9659 37.2708Z"
        fill={fill}
      />
      <path
        d="M36.9659 3.33903L3.35481 36.9501C1.49628 38.8087 1.51203 41.817 3.35481 43.6597C5.21334 45.5183 8.22164 45.5025 10.0644 43.6597L43.6598 10.0644C45.5183 8.20586 45.5026 5.19756 43.6598 3.35478C41.817 1.51199 38.8087 1.49624 36.9659 3.33903Z"
        fill={fill}
      />
    </svg>
  ),
  'header__close-menu-btn--mobile': (fill) => (
    <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.0694 0.35504C1.59601 -0.118347 0.828498 -0.118346 0.355111 0.35504C-0.118275 0.828426 -0.118275 1.59594 0.355111 2.06932L7.78364 9.49786L0.35504 16.9265C-0.118347 17.3998 -0.118346 18.1674 0.35504 18.6407C0.828426 19.1141 1.59594 19.1141 2.06932 18.6407L9.49793 11.2121L16.9265 18.6407C17.3999 19.1141 18.1674 19.1141 18.6408 18.6407C19.1142 18.1673 19.1142 17.3998 18.6408 16.9265L11.2122 9.49786L18.6407 2.06933C19.1141 1.59595 19.1141 0.828437 18.6407 0.355051C18.1673 -0.118336 17.3998 -0.118336 16.9265 0.355051L9.49793 7.78357L2.0694 0.35504Z"
        fill={fill}
      />
    </svg>
  ),
  filter__closed: () => (
    <svg viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6 7l-.576.48.576.692.576-.692L6 7zM.424 1.48l5 6 1.152-.96-5-6-1.152.96zm6.152 6l5-6-1.152-.96-5 6 1.152.96z" />
    </svg>
  ),
  filter__opened: () => (
    <svg viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6 2l.576-.48L6 .828l-.576.692L6 2zm5.576 5.52l-5-6-1.152.96 5 6 1.152-.96zm-6.152-6l-5 6 1.152.96 5-6-1.152-.96z" />
    </svg>
  ),
  button__refresh: () => (
    <svg
      fill="#026DB0"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 30 30"
      width="13px"
      height="13px"
    >
      <path d="M 15 3 C 8.9134751 3 3.87999 7.5533546 3.1132812 13.439453 A 1.0001 1.0001 0 1 0 5.0957031 13.697266 C 5.7349943 8.7893639 9.9085249 5 15 5 C 17.766872 5 20.250574 6.1285473 22.058594 7.9414062 L 20 10 L 26 11 L 25 5 L 23.470703 6.5292969 C 21.300701 4.3575454 18.309289 3 15 3 z M 25.912109 15.417969 A 1.0001 1.0001 0 0 0 24.904297 16.302734 C 24.265006 21.210636 20.091475 25 15 25 C 11.977904 25 9.2987537 23.65024 7.4648438 21.535156 L 9 20 L 3 19 L 4 25 L 6.0488281 22.951172 C 8.2452659 25.422716 11.436061 27 15 27 C 21.086525 27 26.12001 22.446646 26.886719 16.560547 A 1.0001 1.0001 0 0 0 25.912109 15.417969 z" />
    </svg>
  ),
  slider__prev: () => (
    <svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d)">
        <circle
          cx="22.4468"
          cy="20.2683"
          r="17.0964"
          transform="rotate(90 22.4468 20.2683)"
          fill="white"
        />
      </g>
      <path
        d="M23.9121 12.9414L16.5851 20.2684L23.9121 27.5955"
        stroke="#C4C4C4"
        strokeWidth="1.95388"
        strokeLinejoin="round"
      />
      <defs>
        <filter
          id="filter0_d"
          x="0.465646"
          y="0.241057"
          width="43.9623"
          height="43.9623"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1.95388" />
          <feGaussianBlur stdDeviation="2.44235" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
      </defs>
    </svg>
  ),
  slider__next: () => (
    <svg width="44" height="45" viewBox="0 0 44 45" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d)">
        <circle
          r="17.0964"
          transform="matrix(4.37114e-08 1 1 -4.37114e-08 22.1633 20.2683)"
          fill="white"
        />
      </g>
      <path
        d="M19.7205 13.4297L27.0475 20.7567L19.7205 28.0838"
        stroke="#C4C4C4"
        strokeWidth="1.95388"
        strokeLinejoin="round"
      />
      <defs>
        <filter
          id="filter0_d"
          x="0.182199"
          y="0.241057"
          width="43.9623"
          height="43.9623"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1.95388" />
          <feGaussianBlur stdDeviation="2.44235" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
      </defs>
    </svg>
  ),
  option__mark: () => (
    <svg width="8" height="7" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 3.188L3.8 6 7 1" stroke="#026DB0" strokeWidth="1.5" strokeLinejoin="round" />
    </svg>
  ),
  'modal__close-btn': () => (
    <svg viewBox="0 0 365.696 365.696" xmlns="http://www.w3.org/2000/svg">
      <path d="M243.188 182.86L356.32 69.726c12.5-12.5 12.5-32.766 0-45.247L341.238 9.398c-12.504-12.503-32.77-12.503-45.25 0L182.86 122.528 69.727 9.374c-12.5-12.5-32.766-12.5-45.247 0L9.375 24.457c-12.5 12.504-12.5 32.77 0 45.25l113.152 113.152L9.398 295.99c-12.503 12.503-12.503 32.769 0 45.25L24.48 356.32c12.5 12.5 32.766 12.5 45.247 0l113.132-113.132L295.99 356.32c12.503 12.5 32.769 12.5 45.25 0l15.081-15.082c12.5-12.504 12.5-32.77 0-45.25zm0 0" />
    </svg>
  ),
  'type__filter--button': () => (
    <svg width="16" height="13" viewBox="0 0 16 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0)">
        <path
          d="M15.6347 10.8681V9.62068H6.06066C5.80088 8.89546 5.09697 8.37329 4.27573 8.37329C3.4545 8.37329 2.75477 8.89546 2.49499 9.62068H0.492188V10.8681H2.49499C2.75477 11.5933 3.45869 12.1155 4.27992 12.1155C5.10116 12.1155 5.80507 11.5933 6.06485 10.8681H15.6347ZM4.27992 10.8681C3.93215 10.8681 3.64724 10.5863 3.64724 10.2423C3.64724 9.89834 3.93215 9.61654 4.27992 9.61654C4.62769 9.61654 4.91261 9.89834 4.91261 10.2423C4.91261 10.5863 4.62769 10.8681 4.27992 10.8681Z"
          fill="#026DB0"
        />
        <path
          d="M11.8512 8.37327C12.6724 8.37327 13.3764 7.85111 13.6361 7.12588H15.6389V5.87849H13.6361C13.3764 5.15327 12.6724 4.6311 11.8512 4.6311C11.03 4.6311 10.3261 5.15327 10.0663 5.87849H0.492188V7.12588H10.0663C10.3261 7.85111 11.0258 8.37327 11.8512 8.37327ZM11.8512 5.87849C12.199 5.87849 12.4839 6.1603 12.4839 6.50426C12.4839 6.84822 12.199 7.13003 11.8512 7.13003C11.5034 7.13003 11.2185 6.84822 11.2185 6.50426C11.2185 6.1603 11.5034 5.87849 11.8512 5.87849Z"
          fill="#026DB0"
        />
        <path
          d="M4.27992 4.63108C5.10116 4.63108 5.80507 4.10892 6.06485 3.38369H15.6389V2.13216H6.06066C5.80088 1.40693 5.10116 0.884766 4.27992 0.884766C3.45869 0.884766 2.75477 1.40693 2.49499 2.13216H0.492188V3.37955H2.49499C2.75477 4.10892 3.4545 4.63108 4.27992 4.63108ZM4.27992 2.13216C4.62769 2.13216 4.91261 2.41396 4.91261 2.75792C4.91261 3.10189 4.62769 3.38369 4.27992 3.38369C3.93215 3.38369 3.64724 3.10189 3.64724 2.75792C3.64724 2.41396 3.92796 2.13216 4.27992 2.13216Z"
          fill="#026DB0"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect
            width="15.1426"
            height="11.2348"
            fill="white"
            transform="translate(0.492188 0.884766)"
          />
        </clipPath>
      </defs>
    </svg>
  ),
  'type__close-filters-btn': () => (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M43.9659 37.2708L10.3548 3.65974C8.49628 1.80121 5.48797 1.81696 3.64519 3.65974C1.78666 5.51827 1.80241 8.52658 3.64519 10.3694L37.2405 43.9647C39.0991 45.8232 42.1074 45.8075 43.9502 43.9647C45.7929 42.1219 45.8087 39.1136 43.9659 37.2708Z"
        fill="white"
      />
      <path
        d="M36.9659 3.33903L3.35481 36.9501C1.49628 38.8087 1.51203 41.817 3.35481 43.6597C5.21334 45.5183 8.22164 45.5025 10.0644 43.6597L43.6598 10.0644C45.5183 8.20586 45.5026 5.19756 43.6598 3.35478C41.817 1.51199 38.8087 1.49624 36.9659 3.33903Z"
        fill="white"
      />
    </svg>
  ),
  button__reset: () => (
    <svg viewBox="0 0 23 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.316 4.742l10.84 11.769-1.472 1.355L5.844 6.097l1.472-1.355z" fill="#858585" />
      <path d="M5.438 15.988l11.77-10.84 1.354 1.472L6.793 17.46l-1.355-1.472z" fill="#858585" />
    </svg>
  ),
  'trash-basket': () => (
    <svg width="19" height="20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0)" fill="#B0B0B0">
        <path d="M18.27 2.857h-5.116v-.714C13.154.959 12.172 0 10.962 0H8.038c-1.21 0-2.192.96-2.192 2.143v.714H.731c-.404 0-.731.32-.731.714 0 .395.327.715.73.715h.795L2.923 19.35c.035.368.352.65.731.649h11.692a.725.725 0 00.731-.65l1.398-15.064h.794c.404 0 .731-.32.731-.715a.723.723 0 00-.73-.714zM7.308 2.143c0-.395.327-.715.73-.715h2.924c.403 0 .73.32.73.715v.714H7.309v-.714zm7.371 16.428H4.321L2.996 4.286h13.011L14.68 18.57z" />
        <path d="M7.308 16.38v-.002l-.731-10a.727.727 0 00-.783-.663.722.722 0 00-.679.765l.731 10a.725.725 0 00.73.663h.054a.72.72 0 00.678-.762zM9.5 5.714c-.403 0-.73.32-.73.714v10c0 .395.327.714.73.714.404 0 .731-.32.731-.714v-10a.723.723 0 00-.73-.714zM13.206 5.714a.727.727 0 00-.783.663l-.731 10a.72.72 0 00.679.765h.052c.384 0 .703-.29.73-.664l.732-10a.722.722 0 00-.68-.764z" />
      </g>
      <defs>
        <clipPath id="clip0">
          <path fill="#fff" d="M0 0h19v20H0z" />
        </clipPath>
      </defs>
    </svg>
  ),
  'position__offer-btn': () => (
    <svg width="20" height="24" viewBox="0 0 20 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M19.8 18.569H5.51998L3.11998 7.8361H0.599976V6.64355H4.07998L6.47998 17.3765H19.8V18.569Z" />
      <path d="M16.2003 23.3394C15.2103 23.3394 14.4003 22.5344 14.4003 21.5505C14.4003 20.5667 15.2103 19.7617 16.2003 19.7617C17.1903 19.7617 18.0003 20.5667 18.0003 21.5505C18.0003 22.5344 17.1903 23.3394 16.2003 23.3394ZM16.2003 20.9543C15.8703 20.9543 15.6003 21.2226 15.6003 21.5505C15.6003 21.8785 15.8703 22.1468 16.2003 22.1468C16.5303 22.1468 16.8003 21.8785 16.8003 21.5505C16.8003 21.2226 16.5303 20.9543 16.2003 20.9543Z" />
      <path d="M7.1999 23.3394C6.2099 23.3394 5.3999 22.5344 5.3999 21.5505C5.3999 20.5667 6.2099 19.7617 7.1999 19.7617C8.1899 19.7617 8.9999 20.5667 8.9999 21.5505C8.9999 22.5344 8.1899 23.3394 7.1999 23.3394ZM7.1999 20.9543C6.8699 20.9543 6.5999 21.2226 6.5999 21.5505C6.5999 21.8785 6.8699 22.1468 7.1999 22.1468C7.5299 22.1468 7.7999 21.8785 7.7999 21.5505C7.7999 21.2226 7.5299 20.9543 7.1999 20.9543Z" />
      <path d="M14.933 8.73948H17.4349V10.8599H14.933V13.7286H12.6219V10.8599H10.1126V8.73948H12.6219V5.95143H14.933V8.73948Z" />
    </svg>
  ),
  logo__beri: () => (
    <svg width="71" height="26" viewBox="0 0 71 26" xmlns="http://www.w3.org/2000/svg">
      <title id="catTitle">Берибар: Ищи здесь Покупай по своим контрактам"</title>
      <path d="M4.8 8.4h3.6c2.3 0 4.3.8 5.9 2.4 1.6 1.6 2.5 3.6 2.5 5.9 0 2.3-.8 4.3-2.5 5.9-1.6 1.6-3.6 2.4-5.9 2.4H0V1.2h14.4V6H4.8v2.4zm0 12h3.6c1 0 1.8-.4 2.5-1.1.7-.7 1.1-1.6 1.1-2.5 0-.9-.4-1.8-1.1-2.5-.7-.7-1.6-1.1-2.5-1.1H4.8v7.2zM32.3 1.2V6h-9.6v4.8h7.2v4.8h-7.2v4.8h9.6v4.8H17.9v-24h14.4zM33.5 1.2h8.4c2.3 0 4.3.8 5.9 2.4 1.6 1.6 2.5 3.6 2.5 5.9 0 2.3-.8 4.3-2.5 5.9-1.6 1.6-3.6 2.4-5.9 2.4h-3.6V25h-4.8V1.2zm10.9 5.9c-.7-.7-1.5-1.1-2.5-1.1h-3.6v7.2h3.6c1 0 1.8-.4 2.5-1.1.7-.7 1.1-1.6 1.1-2.5 0-.9-.4-1.8-1.1-2.5zM70.6.7v24.4h-4.8V11.9L51.4 25.6V1.2h4.8v13.3L70.6.7z" />
    </svg>
  ),
  'logo__bar--desktop': () => (
    <svg width="28" height="27" viewBox="0 0 28 27" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.2 27c7.456 0 13.5-6.044 13.5-13.5S21.656 0 14.2 0 .7 6.044.7 13.5 6.744 27 14.2 27z"
        fill="#006CB6"
      />
      <path
        d="M6.1 11.9h1.3c.8 0 1.5.3 2.1.9.6.6.9 1.3.9 2.1s-.3 1.5-.9 2.1c-.6.6-1.3.9-2.1.9H4.5V9.4h5.1V11H6.2v.9h-.1zm0 4.2h1.3c.3 0 .6-.1.9-.4.2-.2.4-.5.4-.9s-.1-.6-.4-.9c-.3-.3-.5-.4-.9-.4H6.1v2.6zM14.3 9.2l3.8 8.6h-1.8l-.6-1.3h-2.9l-.6 1.3h-1.8l3.9-8.6zM15 15l-.8-1.8-.7 1.8H15zM18.5 9.4h2.9c.8 0 1.5.3 2.1.9.6.6.9 1.3.9 2.1s-.3 1.5-.9 2.1c-.6.6-1.3.9-2.1.9h-1.3v2.5h-1.7V9.4h.1zm3.9 2c-.2-.3-.5-.4-.9-.4h-1.3v2.5h1.3c.3 0 .6-.1.9-.4.2-.2.4-.5.4-.9s-.2-.5-.4-.8z"
        fill="#fff"
      />
    </svg>
  ),
  header__search: (fill) => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.33333 12.6667C10.2789 12.6667 12.6667 10.2789 12.6667 7.33333C12.6667 4.38781 10.2789 2 7.33333 2C4.38781 2 2 4.38781 2 7.33333C2 10.2789 4.38781 12.6667 7.33333 12.6667Z"
        stroke={fill}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.0015 14.0001L11.1015 11.1001"
        stroke={fill}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  'header__search-cross': () => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.57135 0.856993C2.09796 0.383606 1.33045 0.383607 0.857065 0.856993C0.383678 1.33038 0.383678 2.09789 0.857065 2.57128L8.2856 9.99981L0.856993 17.4284C0.383606 17.9018 0.383607 18.6693 0.856993 19.1427C1.33038 19.6161 2.09789 19.6161 2.57128 19.1427L9.99988 11.7141L17.4285 19.1427C17.9019 19.6161 18.6694 19.6161 19.1428 19.1427C19.6161 18.6693 19.6161 17.9018 19.1428 17.4284L11.7142 9.99981L19.1427 2.57129C19.6161 2.0979 19.6161 1.33039 19.1427 0.857004C18.6693 0.383617 17.9018 0.383617 17.4284 0.857004L9.99988 8.28553L2.57135 0.856993Z"
        fill="white"
      />
    </svg>
  ),
  'position__popup--btn-close': () => (
    <svg width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d)">
        <circle r="16" transform="matrix(4.37114e-08 1 1 -4.37114e-08 23 23)" fill="white" />
      </g>
      <line
        y1="-0.688406"
        x2="17.2692"
        y2="-0.688406"
        transform="matrix(0.705277 0.708932 -0.64662 0.762812 17 17)"
        stroke="#026DB0"
        strokeWidth="1.37681"
      />
      <line
        y1="-0.688406"
        x2="17.2692"
        y2="-0.688406"
        transform="matrix(-0.705277 0.708932 0.64662 0.762812 29.1797 17)"
        stroke="#026DB0"
        strokeWidth="1.37681"
      />
      <defs>
        <filter
          id="filter0_d"
          x="0"
          y="0"
          width="46"
          height="46"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="3.5" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
      </defs>
    </svg>
  ),
  'sorting__item-icon-up': () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <path d="M8,5.70710678 L8,19.508331 C8,19.7844734 7.77614237,20.008331 7.5,20.008331 C7.22385763,20.008331 7,19.7844734 7,19.508331 L7,5.70710678 L4.85355339,7.85355339 C4.65829124,8.04881554 4.34170876,8.04881554 4.14644661,7.85355339 C3.95118446,7.65829124 3.95118446,7.34170876 4.14644661,7.14644661 L7.14644661,4.14644661 C7.34170876,3.95118446 7.65829124,3.95118446 7.85355339,4.14644661 L10.8535534,7.14644661 C11.0488155,7.34170876 11.0488155,7.65829124 10.8535534,7.85355339 C10.6582912,8.04881554 10.3417088,8.04881554 10.1464466,7.85355339 L8,5.70710678 Z M12.5,6 C12.2238576,6 12,5.77614237 12,5.5 C12,5.22385763 12.2238576,5 12.5,5 L20.5,5 C20.7761424,5 21,5.22385763 21,5.5 C21,5.77614237 20.7761424,6 20.5,6 L12.5,6 Z M12.5,10 C12.2238576,10 12,9.77614237 12,9.5 C12,9.22385763 12.2238576,9 12.5,9 L18.5,9 C18.7761424,9 19,9.22385763 19,9.5 C19,9.77614237 18.7761424,10 18.5,10 L12.5,10 Z M12.5,14 C12.2238576,14 12,13.7761424 12,13.5 C12,13.2238576 12.2238576,13 12.5,13 L16.5,13 C16.7761424,13 17,13.2238576 17,13.5 C17,13.7761424 16.7761424,14 16.5,14 L12.5,14 Z M12.5,18 C12.2238576,18 12,17.7761424 12,17.5 C12,17.2238576 12.2238576,17 12.5,17 L14.5,17 C14.7761424,17 15,17.2238576 15,17.5 C15,17.7761424 14.7761424,18 14.5,18 L12.5,18 Z" />
    </svg>
  ),
  'sorting__item-icon-down': () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <path d="M6.90365714,19.8034496 C6.81268627,19.9276666 6.66576323,20.008331 6.5,20.008331 C6.33423677,20.008331 6.18731373,19.9276666 6.09634286,19.8034496 L3.14644661,16.8535534 C2.95118446,16.6582912 2.95118446,16.3417088 3.14644661,16.1464466 C3.34170876,15.9511845 3.65829124,15.9511845 3.85355339,16.1464466 L6,18.2928932 L6,4.5 C6,4.22385763 6.22385763,4 6.5,4 C6.77614237,4 7,4.22385763 7,4.5 L7,18.2928932 L9.14644661,16.1464466 C9.34170876,15.9511845 9.65829124,15.9511845 9.85355339,16.1464466 C10.0488155,16.3417088 10.0488155,16.6582912 9.85355339,16.8535534 L6.90365714,19.8034496 L6.90365714,19.8034496 Z M12.5,6 C12.2238576,6 12,5.77614237 12,5.5 C12,5.22385763 12.2238576,5 12.5,5 L20.5,5 C20.7761424,5 21,5.22385763 21,5.5 C21,5.77614237 20.7761424,6 20.5,6 L12.5,6 Z M12.5,10 C12.2238576,10 12,9.77614237 12,9.5 C12,9.22385763 12.2238576,9 12.5,9 L18.5,9 C18.7761424,9 19,9.22385763 19,9.5 C19,9.77614237 18.7761424,10 18.5,10 L12.5,10 Z M12.5,14 C12.2238576,14 12,13.7761424 12,13.5 C12,13.2238576 12.2238576,13 12.5,13 L16.5,13 C16.7761424,13 17,13.2238576 17,13.5 C17,13.7761424 16.7761424,14 16.5,14 L12.5,14 Z M12.5,18 C12.2238576,18 12,17.7761424 12,17.5 C12,17.2238576 12.2238576,17 12.5,17 L14.5,17 C14.7761424,17 15,17.2238576 15,17.5 C15,17.7761424 14.7761424,18 14.5,18 L12.5,18 Z" />
    </svg>
  ),
  'sorting__item-icon': () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      focusable="false"
      viewBox="0 0 11 12"
    >
      <path
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        d="M2.5 4L5.6.9c.2-.2.5-.2.7 0L9.5 4m-7 4l3.1 3.1c.2.2.5.2.7 0L9.5 8"
      />
    </svg>
  ),
  'position__description-icon-up': () => (
    <svg width="12" height="9" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6 2L6.57617 1.51986L6 0.828462L5.42383 1.51986L6 2ZM11.5762 7.51986L6.57617 1.51986L5.42383 2.48014L10.4238 8.48014L11.5762 7.51986ZM5.42383 1.51986L0.423835 7.51986L1.57617 8.48014L6.57617 2.48014L5.42383 1.51986Z"
        fill="#026DB0"
      />
    </svg>
  ),
  'position__description-icon-down': () => (
    <svg width="12" height="9" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6 7L5.42383 7.48014L6 8.17154L6.57617 7.48014L6 7ZM0.423834 1.48014L5.42383 7.48014L6.57617 6.51986L1.57617 0.519862L0.423834 1.48014ZM6.57617 7.48014L11.5762 1.48014L10.4238 0.519862L5.42383 6.51986L6.57617 7.48014Z"
        fill="#026DB0"
      />
    </svg>
  ),
  paginator__prev: () => (
    <svg width="10" height="17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.327 1L1 8.327l7.327 7.327"
        stroke="#C4C4C4"
        strokeWidth="1.954"
        strokeLinejoin="round"
      />
    </svg>
  ),
  paginator__next: () => (
    <svg width="10" height="17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.327 1l7.327 7.327-7.327 7.327"
        stroke="#C4C4C4"
        strokeWidth="1.954"
        strokeLinejoin="round"
      />
    </svg>
  ),
  'position__link-mobile-arrow': () => (
    <svg
      fill="#707070"
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 476.213 476.213"
    >
      <polygon
        points="476.213,223.107 57.427,223.107 151.82,128.713 130.607,107.5 0,238.106 130.607,368.714 151.82,347.5
	              57.427,253.107 476.213,253.107 "
      />
    </svg>
  ),
  'header__location--mobile': () => (
    <svg width="9" height="12" viewBox="0 0 9 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.4 0C1.974 0 0 1.95 0 4.346c0 2.974 3.938 7.34 4.105 7.525a.4.4 0 00.59 0C4.863 11.686 8.8 7.32 8.8 4.346 8.8 1.95 6.826 0 4.4 0zm0 6.533c-1.22 0-2.214-.981-2.214-2.187S3.18 2.16 4.4 2.16s2.214.98 2.214 2.186S5.621 6.533 4.4 6.533z"
        fill="#026DB0"
      />
    </svg>
  ),
  header__location: (fill = '#CBD2D8') => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.99992 1.66663C6.49992 1.66663 3.33325 4.34996 3.33325 8.49996C3.33325 11.2666 5.55825 14.5416 9.99992 18.3333C14.4416 14.5416 16.6666 11.2666 16.6666 8.49996C16.6666 4.34996 13.4999 1.66663 9.99992 1.66663ZM9.99992 9.99996C9.08325 9.99996 8.33325 9.24996 8.33325 8.33329C8.33325 7.41663 9.08325 6.66663 9.99992 6.66663C10.9166 6.66663 11.6666 7.41663 11.6666 8.33329C11.6666 9.24996 10.9166 9.99996 9.99992 9.99996Z"
        fill={fill}
      />
    </svg>
  ),
  information: (fill = '#CBD2D8') => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.5 8C15.5 12.1421 12.1421 15.5 8 15.5C3.85786 15.5 0.5 12.1421 0.5 8C0.5 3.85786 3.85786 0.5 8 0.5C12.1421 0.5 15.5 3.85786 15.5 8ZM8.83333 4.66667C8.83333 5.1269 8.46024 5.5 8 5.5C7.53976 5.5 7.16667 5.1269 7.16667 4.66667C7.16667 4.20643 7.53976 3.83333 8 3.83333C8.46024 3.83333 8.83333 4.20643 8.83333 4.66667ZM9 12.1667V7.16667H7V12.1667H9Z"
        fill={fill}
      />
    </svg>
  ),
  header__phone: (fill = '#CBD2D8') => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.51667 6.99167C4.71667 9.35 6.65 11.275 9.00833 12.4833L10.8417 10.65C11.0667 10.425 11.4 10.35 11.6917 10.45C12.625 10.7583 13.6333 10.925 14.6667 10.925C15.125 10.925 15.5 11.3 15.5 11.7583V14.6667C15.5 15.125 15.125 15.5 14.6667 15.5C6.84167 15.5 0.5 9.15833 0.5 1.33333C0.5 0.875 0.875 0.5 1.33333 0.5H4.25C4.70833 0.5 5.08333 0.875 5.08333 1.33333C5.08333 2.375 5.25 3.375 5.55833 4.30833C5.65 4.6 5.58333 4.925 5.35 5.15833L3.51667 6.99167Z"
        fill={fill}
      />
    </svg>
  ),
  header__user: (fill) => (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.0001 0.666626C6.64008 0.666626 0.666748 6.63996 0.666748 14C0.666748 21.36 6.64008 27.3333 14.0001 27.3333C21.3601 27.3333 27.3334 21.36 27.3334 14C27.3334 6.63996 21.3601 0.666626 14.0001 0.666626ZM14.0001 4.66663C16.2134 4.66663 18.0001 6.45329 18.0001 8.66663C18.0001 10.88 16.2134 12.6666 14.0001 12.6666C11.7867 12.6666 10.0001 10.88 10.0001 8.66663C10.0001 6.45329 11.7867 4.66663 14.0001 4.66663ZM14.0001 23.6C10.6667 23.6 7.72008 21.8933 6.00008 19.3066C6.04008 16.6533 11.3334 15.2 14.0001 15.2C16.6534 15.2 21.9601 16.6533 22.0001 19.3066C20.2801 21.8933 17.3334 23.6 14.0001 23.6Z"
        fill={fill || 'url(#userGradient)'}
      />
      <defs>
        <linearGradient
          id="userGradient"
          x1="14.0001"
          y1="0.666626"
          x2="14.0001"
          y2="27.3333"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0168B2" />
          <stop offset="1" stopColor="#0483DF" />
        </linearGradient>
      </defs>
    </svg>
  ),
  winery_phone: (fill = '#026DB0') => (
    <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="6.5" cy="6.5" r="6.5" fill={fill} />
      <path
        d="M7.83066 10.9991C5.11078 11.0755 0.296026 6.26198 2.61133 3.64586C2.61962 3.6379 2.97586 3.32793 2.97586 3.32793C3.43973 2.8997 4.15721 2.90564 4.61389 3.34152L5.33215 4.11409C5.77209 4.57326 5.7663 5.29849 5.31917 5.75063L5.18963 5.88202C5.57528 6.73489 6.25771 7.41919 7.11005 7.80774L7.24726 7.67448C7.70941 7.23499 8.43566 7.23499 8.89765 7.67448L9.64423 8.36612C10.1045 8.81513 10.1167 9.55067 9.67161 10.0145C9.67161 10.0145 9.36121 10.3698 9.35324 10.3781C8.94928 10.7801 8.40077 11.0038 7.83066 10.9991ZM3.30816 4.36657C1.6864 6.37228 6.87741 11.2567 8.63325 9.68253C8.63325 9.68253 8.94161 9.3296 8.95006 9.32163C9.01515 9.25648 9.01515 9.15103 8.95006 9.08604L8.20317 8.39471C8.10304 8.31143 8.04656 8.31034 7.95379 8.38143L7.57471 8.75404C7.435 8.89121 7.22802 8.93449 7.04497 8.86465C5.69747 8.36393 4.63407 7.30358 4.13062 5.95857C4.05662 5.77422 4.09886 5.56362 4.23841 5.42192L4.60826 5.04665C4.67506 4.98743 4.68117 4.88526 4.62171 4.81839C4.61843 4.81464 4.61483 4.81105 4.61123 4.80761L3.89234 4.03504C3.82632 3.97989 3.7287 3.98583 3.66972 4.04832C3.66158 4.05691 3.30816 4.36657 3.30816 4.36657ZM10 6.16636C9.99797 4.41843 8.57959 3.00203 6.82938 3C6.55294 3 6.32874 3.22388 6.32874 3.49994C6.32874 3.77601 6.55294 3.99989 6.82938 3.99989C8.02685 4.00129 8.99731 4.9704 8.99888 6.16636C8.99888 6.44243 9.22307 6.66631 9.49952 6.66631C9.77596 6.66631 10 6.44243 10 6.16636ZM8.3313 6.16636C8.33036 5.33849 7.65841 4.66747 6.82938 4.66653C6.55294 4.66653 6.32874 4.89041 6.32874 5.16648C6.32874 5.44254 6.55294 5.66642 6.82938 5.66642C7.10583 5.66642 7.33002 5.8903 7.33002 6.16636C7.33002 6.44243 7.55422 6.66631 7.83066 6.66631C8.10711 6.66631 8.3313 6.44243 8.3313 6.16636Z"
        fill="white"
      />
    </svg>
  ),
  winery_site: (fill = '#026DB0') => (
    <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.5 0C2.91027 0 0 2.91027 0 6.5C0 10.0897 2.91027 13 6.5 13C10.0897 13 13 10.0897 13 6.5C12.9962 2.9118 10.0882 0.00380859 6.5 0ZM6.77092 11.3615V10.6656C6.77092 9.41205 5.75479 8.39592 4.50125 8.39592H4.49592C4.04422 8.39592 3.61105 8.21641 3.29189 7.89699L1.6382 6.24229C1.77607 3.55799 4.06377 1.49348 6.74807 1.63135C7.63064 1.67654 8.48402 1.96143 9.21705 2.45502C9.07359 2.56039 8.90043 2.61727 8.72244 2.61752H7.94574C7.48059 2.61752 7.10353 2.99457 7.10353 3.45973V3.46125V4.02568C7.10353 4.49084 6.72648 4.86789 6.26133 4.86789C5.79617 4.86662 5.41811 5.24291 5.41684 5.70807V5.71035V6.27479C5.41684 6.73994 5.79389 7.11699 6.25904 7.11699H8.51018C8.97533 7.11699 9.35238 7.49404 9.35238 7.9592V8.78262C9.35238 9.00605 9.44125 9.22061 9.59943 9.37854L10.0506 9.82973C9.19572 10.7438 8.02065 11.2927 6.77092 11.3615Z"
        fill={fill}
      />
    </svg>
  ),
  clients_cart: () => (
    <svg width="36" height="43" viewBox="0 0 36 43" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M36 33.3173H9.225L4.725 13.1931H0V10.957H6.525L11.025 31.0813H36V33.3173Z"
        fill="white"
      />
      <path
        d="M29.2505 42.2618C27.3942 42.2618 25.8755 40.7525 25.8755 38.9077C25.8755 37.063 27.3942 35.5537 29.2505 35.5537C31.1067 35.5537 32.6255 37.063 32.6255 38.9077C32.6255 40.7525 31.1067 42.2618 29.2505 42.2618ZM29.2505 37.7897C28.6317 37.7897 28.1255 38.2928 28.1255 38.9077C28.1255 39.5227 28.6317 40.0258 29.2505 40.0258C29.8692 40.0258 30.3755 39.5227 30.3755 38.9077C30.3755 38.2928 29.8692 37.7897 29.2505 37.7897Z"
        fill="white"
      />
      <path
        d="M12.3745 42.2618C10.5183 42.2618 8.99951 40.7525 8.99951 38.9077C8.99951 37.063 10.5183 35.5537 12.3745 35.5537C14.2308 35.5537 15.7495 37.063 15.7495 38.9077C15.7495 40.7525 14.2308 42.2618 12.3745 42.2618ZM12.3745 37.7897C11.7558 37.7897 11.2495 38.2928 11.2495 38.9077C11.2495 39.5227 11.7558 40.0258 12.3745 40.0258C12.9933 40.0258 13.4995 39.5227 13.4995 38.9077C13.4995 38.2928 12.9933 37.7897 12.3745 37.7897Z"
        fill="white"
      />
      <path
        d="M26.8746 14.6369H31.5656V18.6126H26.8746V23.9915H22.5412V18.6126H17.8363V14.6369H22.5412V9.40931H26.8746V14.6369Z"
        fill="white"
      />
    </svg>
  ),
  clients_phone: () => (
    <svg width="35" height="34" viewBox="0 0 35 34" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0)">
        <path
          d="M2.0001 34L5.7001 34C18.9001 33.8 34.0001 16.9 34.1001 5.6L34.1001 1.9C34.1001 0.900002 33.3001 6.99382e-08 32.2001 1.66103e-07L24.7001 0.0999993C23.7001 0.0999994 22.8001 1 22.8001 2L22.6001 10.2L27.3001 14.2C27.3001 16.8 16.8001 27.3 14.1001 27.4L9.8001 22.7L1.9001 22.9C0.900096 22.9 9.82904e-05 23.8 9.83778e-05 24.8L0.100097 32.1C0.100098 33.2 0.900101 34 2.0001 34Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect
            width="34.1"
            height="34"
            fill="white"
            transform="translate(34.1001 34) rotate(180)"
          />
        </clipPath>
      </defs>
    </svg>
  ),
  clients_mail: () => (
    <svg width="65" height="31" viewBox="0 0 65 31" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2 23.3105H15.3188"
        stroke="white"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.32715 15.3193H16.6503"
        stroke="white"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.6553 7.32812H17.9828"
        stroke="white"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M60.603 28.6377H20.6465L23.3103 2H63.2668L60.603 28.6377Z"
        stroke="white"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.3101 2L43.2883 17.9826L63.2666 2"
        stroke="white"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  'position__review-star': (width, height) => (
    <svg
      width={width}
      height={height}
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.80932 12.9792C2.67177 12.9792 2.53521 12.9343 2.41878 12.8464C2.20165 12.6821 2.10021 12.3999 2.15941 12.1277L2.96676 8.41613L0.22834 5.91019C0.0269311 5.72693 -0.0499481 5.43653 0.032826 5.17074C0.115355 4.90546 0.340343 4.71784 0.606595 4.69221L4.22974 4.34901L5.6622 0.850645C5.76782 0.593823 6.00853 0.427734 6.27576 0.427734C6.543 0.427734 6.78346 0.593823 6.88932 0.850132L8.32178 4.34901L11.9444 4.69221C12.2112 4.71733 12.4362 4.90571 12.5187 5.17074C12.6012 5.43602 12.5248 5.72693 12.3237 5.91019L9.58525 8.41561L10.3926 12.1272C10.4518 12.4002 10.3504 12.6824 10.1332 12.8459C9.91659 13.0097 9.62799 13.0223 9.40005 12.8792L6.27576 10.931L3.15147 12.8805C3.04585 12.9459 2.9282 12.9792 2.80932 12.9792ZM6.27576 10.0919C6.3944 10.0919 6.51205 10.1252 6.61791 10.1906L9.56658 12.0309L8.80442 8.52762C8.75014 8.27823 8.8312 8.01808 9.01566 7.84943L11.6013 5.48318L8.18055 5.15895C7.93419 5.13537 7.72247 4.97389 7.62618 4.73706L6.27576 1.4358L4.92387 4.73758C4.82857 4.97287 4.61684 5.13434 4.37122 5.15792L0.949727 5.4819L3.53537 7.84814C3.72057 8.01731 3.80163 8.27721 3.74661 8.52711L2.98518 12.0301L5.93386 10.1903C6.03923 10.1249 6.15688 10.0919 6.27576 10.0919ZM4.20223 4.41668C4.20223 4.41668 4.20223 4.41719 4.20174 4.4177L4.20223 4.41668ZM8.34831 4.41488L8.3488 4.41591C8.3488 4.41539 8.3488 4.41539 8.34831 4.41488Z"
        fill="#FFC01D"
      />
    </svg>
  ),
  'position__review-user': (width, height) => (
    <svg
      width={width}
      height={height}
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path
          d="M12.9999 17.0254C9.36959 17.0254 6.41565 19.9788 6.41565 23.6097C6.41565 23.8199 6.42581 24.0276 6.44561 24.2323C8.41338 25.3845 10.6589 26 12.9999 26C15.341 26 17.5865 25.3845 19.5543 24.2318C19.5736 24.0266 19.5842 23.8189 19.5842 23.6092C19.5842 19.9788 16.6303 17.0254 12.9999 17.0254Z"
          fill="#B7B7B7"
        />
        <path
          d="M22.1924 3.80758C19.7371 1.3523 16.4724 0 13 0C9.52758 0 6.26285 1.3523 3.80758 3.80758C1.3523 6.26285 0 9.52758 0 13C0 16.4724 1.3523 19.7371 3.80758 22.1924C4.15695 22.5423 4.52359 22.8683 4.90445 23.1725C5.07508 19.9804 7.10023 17.2727 9.9191 16.1103C8.36926 15.1003 7.34297 13.3519 7.34297 11.3679C7.34297 8.2484 9.88051 5.71086 13 5.71086C16.1195 5.71086 18.657 8.2484 18.657 11.3679C18.657 13.3519 17.6307 15.0998 16.0809 16.1103C18.9003 17.2727 20.9254 19.9804 21.0955 23.1725C21.4764 22.8683 21.8425 22.5418 22.1924 22.1924C24.6477 19.7371 26 16.4724 26 13C26 9.52758 24.6477 6.26285 22.1924 3.80758Z"
          fill="#B7B7B7"
        />
        <path
          d="M17.1338 11.368C17.1338 9.0884 15.2792 7.23438 13.0002 7.23438C10.7211 7.23438 8.86658 9.08891 8.86658 11.368C8.86658 13.647 10.7211 15.5016 13.0002 15.5016C15.2792 15.5016 17.1338 13.647 17.1338 11.368Z"
          fill="#B7B7B7"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="26" height="26" fill="white" />
        </clipPath>
      </defs>
    </svg>
  ),
  'position__review-star-gray': (width, height) => (
    <svg
      width={width}
      height={height}
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.78442 16.5168C3.60757 16.5168 3.43198 16.4592 3.28229 16.3461C3.00312 16.1349 2.87269 15.7721 2.9488 15.4221L3.98685 10.6499L0.465949 7.42792C0.20699 7.19229 0.108143 6.81892 0.214569 6.47718C0.320679 6.13609 0.609956 5.89487 0.952287 5.86191L5.61071 5.42065L7.45248 0.922659C7.58828 0.592453 7.89777 0.378906 8.24136 0.378906C8.58496 0.378906 8.89413 0.592453 9.03024 0.922L10.872 5.42065L15.5298 5.86191C15.8728 5.89421 16.162 6.13642 16.2682 6.47718C16.3743 6.81826 16.276 7.19229 16.0174 7.42792L12.4965 10.6492L13.5346 15.4214C13.6107 15.7724 13.4802 16.1352 13.2011 16.3455C12.9225 16.556 12.5515 16.5722 12.2584 16.3883L8.24136 13.8834L4.22433 16.39C4.08854 16.474 3.93727 16.5168 3.78442 16.5168ZM8.24136 12.8045C8.39389 12.8045 8.54516 12.8473 8.68127 12.9314L12.4725 15.2975L11.4926 10.7933C11.4228 10.4726 11.527 10.1381 11.7642 9.92127L15.0886 6.87889L10.6904 6.46202C10.3737 6.4317 10.1014 6.22408 9.97765 5.91958L8.24136 1.67501L6.50317 5.92024C6.38064 6.22276 6.10842 6.43038 5.79262 6.4607L1.39347 6.87724L4.71793 9.91962C4.95605 10.1371 5.06027 10.4713 4.98953 10.7926L4.01053 15.2965L7.80176 12.931C7.93724 12.847 8.08851 12.8045 8.24136 12.8045ZM5.57534 5.50765C5.57534 5.50765 5.57534 5.50831 5.57471 5.50897L5.57534 5.50765ZM10.9061 5.50534L10.9067 5.50666C10.9067 5.506 10.9067 5.506 10.9061 5.50534Z"
        fill="#B0B0B0"
      />
    </svg>
  ),
  'position__review-star-filled': (width, height) => (
    <svg
      width={width}
      height={height}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.99225 13.3688C2.84575 13.3688 2.70029 13.321 2.57629 13.2274C2.34502 13.0524 2.23697 12.7518 2.30002 12.4619L3.15995 8.50857L0.243209 5.83946C0.0286848 5.64426 -0.0532005 5.33495 0.0349636 5.05185C0.122866 4.7693 0.362505 4.56946 0.646095 4.54216L4.50517 4.17662L6.03091 0.450449C6.1434 0.176904 6.39978 0 6.68442 0C6.96906 0 7.22518 0.176904 7.33793 0.449903L8.86367 4.17662L12.7222 4.54216C13.0063 4.56892 13.246 4.76957 13.3339 5.05185C13.4218 5.33441 13.3404 5.64426 13.1262 5.83946L10.2094 8.50802L11.0693 12.4613C11.1324 12.7521 11.0243 13.0526 10.7931 13.2268C10.5623 13.4013 10.2549 13.4146 10.0122 13.2623L6.68442 11.1872L3.35668 13.2637C3.24419 13.3333 3.11888 13.3688 2.99225 13.3688Z"
        fill="#FFC01D"
      />
    </svg>
  ),
  share__icon: () => (
    <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13 6.49645L7.07352 0V3.89162H6.38494C4.67961 3.89162 3.07614 4.55559 1.87017 5.76113C0.664198 6.96668 0 8.56934 0 10.2743V13L1.13739 11.7541C2.65883 10.0877 4.81855 9.12387 7.07352 9.10178V12.9929L13 6.49645ZM0.761987 11.0416V10.2746C0.761987 8.77322 1.34694 7.3615 2.40889 6.29992C3.47085 5.23834 4.88306 4.65359 6.38494 4.65359H7.83551V1.966L11.9688 6.4967L7.83551 11.0271V8.3398H7.15175C4.74642 8.3398 2.43328 9.32115 0.761987 11.0416Z"
        fill="black"
      />
    </svg>
  ),
  social__tw: () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path
        d="M19.953 7.983c.012.174.012.347.012.523C19.965 13.844 15.837 20 8.29 20v-.003A11.75 11.75 0 012 18.186a8.322 8.322 0 006.073-1.674c-1.756-.033-3.296-1.16-3.834-2.806a4.152 4.152 0 001.853-.07C4.178 13.256 2.8 11.6 2.8 9.676v-.05c.57.312 1.21.486 1.863.505a4.007 4.007 0 01-1.27-5.394 11.708 11.708 0 008.456 4.22 4.002 4.002 0 011.187-3.86 4.153 4.153 0 015.806.176c.919-.178 1.8-.51 2.606-.98a4.067 4.067 0 01-1.804 2.233A8.26 8.26 0 0022 5.89a8.267 8.267 0 01-2.047 2.093z"
        fill="#1D9BF0"
      />
    </svg>
  ),
  social__ok: () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path
        fill="#ED812B"
        d="M11.098 15.678c-1.42-.145-2.7-.489-3.797-1.331-.136-.105-.276-.206-.4-.323-.48-.453-.528-.972-.149-1.506.325-.457.87-.58 1.437-.317.11.05.214.114.315.182 2.043 1.38 4.85 1.418 6.9.063.203-.154.42-.278.672-.342.49-.123.946.053 1.209.474.3.48.296.95-.074 1.323-.567.571-1.249.985-2.007 1.274-.716.273-1.501.41-2.278.502.117.125.172.187.246.26 1.055 1.04 2.114 2.077 3.165 3.122.359.356.433.797.236 1.211-.215.453-.698.75-1.171.718-.3-.02-.534-.167-.742-.372-.796-.787-1.607-1.56-2.387-2.362-.227-.233-.336-.189-.536.014-.801.81-1.615 1.607-2.434 2.4-.367.357-.805.42-1.231.217-.453-.216-.742-.67-.72-1.127.016-.309.17-.545.386-.757 1.044-1.024 2.085-2.05 3.127-3.077.069-.068.133-.14.233-.246z"
      />
      <path
        fill="#ED812B"
        d="M11.963 12.11c-2.533-.008-4.61-2.071-4.596-4.565C7.382 5.025 9.461 2.993 12.02 3c2.564.007 4.621 2.067 4.609 4.615-.014 2.488-2.105 4.504-4.665 4.496zm2.303-4.559C14.262 6.313 13.258 5.328 12 5.327c-1.268-.002-2.28 1.002-2.271 2.251.01 1.233 1.022 2.212 2.282 2.207 1.257-.004 2.258-.996 2.254-2.234z"
      />
    </svg>
  ),
  social__vk: () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path
        d="M2 11.583c0-4.517 0-6.776 1.403-8.18C4.807 2 7.066 2 11.583 2h.834c4.517 0 6.776 0 8.18 1.403C22 4.807 22 7.066 22 11.583v.834c0 4.517 0 6.776-1.404 8.18C19.194 22 16.934 22 12.416 22h-.833c-4.517 0-6.776 0-8.18-1.404C2 19.194 2 16.934 2 12.416v-.833z"
        fill="#2787F5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.417 8.25H5.958c-.417 0-.5.196-.5.412 0 .387.495 2.302 2.303 4.836 1.205 1.73 2.903 2.669 4.449 2.669.927 0 1.042-.209 1.042-.568v-1.307c0-.417.087-.5.381-.5.216 0 .587.108 1.453.942.989.99 1.152 1.433 1.708 1.433h1.459c.416 0 .625-.209.505-.62-.132-.41-.604-1.004-1.23-1.709-.34-.401-.85-.834-1.005-1.05-.216-.279-.155-.402 0-.65 0 0 1.777-2.502 1.962-3.352.093-.309 0-.536-.44-.536h-1.46c-.37 0-.541.196-.634.412 0 0-.742 1.808-1.793 2.982-.34.34-.494.448-.68.448-.092 0-.226-.108-.226-.417V8.786c0-.37-.108-.536-.417-.536h-2.292c-.232 0-.372.172-.372.335 0 .352.526.433.58 1.422v2.147c0 .471-.085.556-.27.556-.495 0-1.698-1.815-2.411-3.893-.14-.404-.28-.567-.653-.567z"
        fill="#fff"
      />
    </svg>
  ),
  social__tg: () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
      <path
        d="M10 20c5.523 0 10-4.477 10-10S15.523 0 10 0 0 4.477 0 10s4.477 10 10 10z"
        fill="#29B6F6"
      />
      <path
        d="M14.975 5.5l-1.873 9.563s-.08.437-.623.437c-.287 0-.436-.137-.436-.137l-4.057-3.367-1.985-1-2.548-.678S3 10.188 3 9.813c0-.313.466-.462.466-.462l10.659-4.234c-.001 0 .325-.117.563-.117.146 0 .312.063.312.25 0 .125-.025.25-.025.25z"
        fill="#fff"
      />
      <path
        d="M9.5 13.253L7.787 14.94s-.074.058-.174.06a.247.247 0 01-.11-.021l.483-2.983L9.5 13.253z"
        fill="#B0BEC5"
      />
      <path
        d="M12.948 7.098a.25.25 0 00-.35-.047L6 11s1.053 2.946 1.213 3.456c.162.51.29.522.29.522l.482-2.982 4.917-4.548a.25.25 0 00.046-.35z"
        fill="#CFD8DC"
      />
    </svg>
  ),
  social__fb: () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path
        d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.992 3.656 9.129 8.438 9.879V14.89h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.262c-1.242 0-1.629.772-1.629 1.563V12h2.774l-.443 2.89h-2.33v6.989C18.343 21.129 22 16.992 22 12z"
        fill="#1877F2"
      />
      <path
        d="M15.893 14.89l.443-2.89h-2.773v-1.875c0-.791.386-1.563 1.628-1.563h1.262v-2.46s-1.144-.196-2.238-.196c-2.285 0-3.777 1.385-3.777 3.89V12h-2.54v2.89h2.54v6.989a10.058 10.058 0 003.124 0V14.89h2.33z"
        fill="#fff"
      />
    </svg>
  ),
  'search__delete-icon': () => (
    <svg
      fill="#A5A5A5"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 30 30"
      width="20px"
      height="20px"
    >
      <path d="M 7 4 C 6.744125 4 6.4879687 4.0974687 6.2929688 4.2929688 L 4.2929688 6.2929688 C 3.9019687 6.6839688 3.9019687 7.3170313 4.2929688 7.7070312 L 11.585938 15 L 4.2929688 22.292969 C 3.9019687 22.683969 3.9019687 23.317031 4.2929688 23.707031 L 6.2929688 25.707031 C 6.6839688 26.098031 7.3170313 26.098031 7.7070312 25.707031 L 15 18.414062 L 22.292969 25.707031 C 22.682969 26.098031 23.317031 26.098031 23.707031 25.707031 L 25.707031 23.707031 C 26.098031 23.316031 26.098031 22.682969 25.707031 22.292969 L 18.414062 15 L 25.707031 7.7070312 C 26.098031 7.3170312 26.098031 6.6829688 25.707031 6.2929688 L 23.707031 4.2929688 C 23.316031 3.9019687 22.682969 3.9019687 22.292969 4.2929688 L 15 11.585938 L 7.7070312 4.2929688 C 7.5115312 4.0974687 7.255875 4 7 4 z" />
    </svg>
  ),
  copy__icon: () => (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 64 64"
      enableBackground="new 0 0 64 64"
    >
      <g id="Text-files">
        <path
          d="M53.9791489,9.1429005H50.010849c-0.0826988,0-0.1562004,0.0283995-0.2331009,0.0469999V5.0228
		C49.7777481,2.253,47.4731483,0,44.6398468,0h-34.422596C7.3839517,0,5.0793519,2.253,5.0793519,5.0228v46.8432999
		c0,2.7697983,2.3045998,5.0228004,5.1378999,5.0228004h6.0367002v2.2678986C16.253952,61.8274002,18.4702511,64,21.1954517,64
		h32.783699c2.7252007,0,4.9414978-2.1725998,4.9414978-4.8432007V13.9861002
		C58.9206467,11.3155003,56.7043495,9.1429005,53.9791489,9.1429005z M7.1110516,51.8661003V5.0228
		c0-1.6487999,1.3938999-2.9909999,3.1062002-2.9909999h34.422596c1.7123032,0,3.1062012,1.3422,3.1062012,2.9909999v46.8432999
		c0,1.6487999-1.393898,2.9911003-3.1062012,2.9911003h-34.422596C8.5049515,54.8572006,7.1110516,53.5149002,7.1110516,51.8661003z
		 M56.8888474,59.1567993c0,1.550602-1.3055,2.8115005-2.9096985,2.8115005h-32.783699
		c-1.6042004,0-2.9097996-1.2608986-2.9097996-2.8115005v-2.2678986h26.3541946
		c2.8333015,0,5.1379013-2.2530022,5.1379013-5.0228004V11.1275997c0.0769005,0.0186005,0.1504021,0.0469999,0.2331009,0.0469999
		h3.9682999c1.6041985,0,2.9096985,1.2609005,2.9096985,2.8115005V59.1567993z"
        />
        <path
          d="M38.6031494,13.2063999H16.253952c-0.5615005,0-1.0159006,0.4542999-1.0159006,1.0158005
		c0,0.5615997,0.4544001,1.0158997,1.0159006,1.0158997h22.3491974c0.5615005,0,1.0158997-0.4542999,1.0158997-1.0158997
		C39.6190491,13.6606998,39.16465,13.2063999,38.6031494,13.2063999z"
        />
        <path
          d="M38.6031494,21.3334007H16.253952c-0.5615005,0-1.0159006,0.4542999-1.0159006,1.0157986
		c0,0.5615005,0.4544001,1.0159016,1.0159006,1.0159016h22.3491974c0.5615005,0,1.0158997-0.454401,1.0158997-1.0159016
		C39.6190491,21.7877007,39.16465,21.3334007,38.6031494,21.3334007z"
        />
        <path
          d="M38.6031494,29.4603004H16.253952c-0.5615005,0-1.0159006,0.4543991-1.0159006,1.0158997
		s0.4544001,1.0158997,1.0159006,1.0158997h22.3491974c0.5615005,0,1.0158997-0.4543991,1.0158997-1.0158997
		S39.16465,29.4603004,38.6031494,29.4603004z"
        />
        <path
          d="M28.4444485,37.5872993H16.253952c-0.5615005,0-1.0159006,0.4543991-1.0159006,1.0158997
		s0.4544001,1.0158997,1.0159006,1.0158997h12.1904964c0.5615025,0,1.0158005-0.4543991,1.0158005-1.0158997
		S29.0059509,37.5872993,28.4444485,37.5872993z"
        />
      </g>
    </svg>
  ),
  'star--grey': () => (
    <svg width="15" height="15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.638 14.34a.74.74 0 0 1-.446-.151.813.813 0 0 1-.297-.822l.923-4.24-3.13-2.863a.816.816 0 0 1-.223-.845.77.77 0 0 1 .656-.547l4.14-.392L6.897.483A.762.762 0 0 1 7.598 0c.306 0 .58.19.701.483L9.936 4.48l4.139.392a.769.769 0 0 1 .656.547.818.818 0 0 1-.223.845L11.38 9.126l.923 4.241a.812.812 0 0 1-.297.821.734.734 0 0 1-.837.038L7.598 12l-3.57 2.228a.74.74 0 0 1-.39.112Zm3.96-3.299c.136 0 .27.039.391.113l3.369 2.103-.87-4.003a.817.817 0 0 1 .24-.775l2.955-2.703-3.909-.37a.765.765 0 0 1-.633-.482L7.598 1.152 6.054 4.924a.763.763 0 0 1-.632.48l-3.909.37 2.954 2.704c.212.193.304.49.242.776l-.87 4.002 3.369-2.102a.738.738 0 0 1 .39-.113ZM5.23 4.557v.002-.002Zm4.737-.002v.002-.002Z"
        fill="#C4C4C4"
      />
    </svg>
  ),
  'star--red': (_fill, width = 0, _height) => (
    <svg width={`${width}%`} height="15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.187 14.34a.74.74 0 0 1-.446-.151.813.813 0 0 1-.297-.822l.923-4.24-3.129-2.863a.816.816 0 0 1-.223-.845.77.77 0 0 1 .655-.547l4.14-.392L7.447.483a.762.762 0 0 1 .7-.483c.306 0 .58.19.702.483l1.636 3.997 4.14.392a.769.769 0 0 1 .655.547.818.818 0 0 1-.223.845L11.93 9.126l.922 4.241a.812.812 0 0 1-.296.821.734.734 0 0 1-.838.038L8.147 12l-3.57 2.228a.74.74 0 0 1-.39.112Zm3.96-3.299c.136 0 .27.039.391.113l3.37 2.103-.871-4.003a.817.817 0 0 1 .24-.775l2.955-2.703-3.908-.37a.765.765 0 0 1-.634-.482L8.148 1.152 6.603 4.924a.763.763 0 0 1-.631.48l-3.91.37 2.955 2.704c.211.193.304.49.24.776l-.87 4.002 3.37-2.102a.738.738 0 0 1 .39-.113ZM5.779 4.557v.002-.002Zm4.737-.002.001.002v-.002Z"
        fill="#BC1B2D"
      />
    </svg>
  ),
  'header__menu-logo--mobile': () => (
    <svg width="98" height="27" viewBox="0 0 98 27" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.67536 8.18199H8.18188C10.4222 8.18199 12.3702 8.96121 13.9287 10.5197C15.4871 12.0781 16.3638 14.0262 16.3638 16.2665C16.3638 18.5067 15.5845 20.4548 13.9287 22.0133C12.3702 23.5717 10.4222 24.3509 8.18188 24.3509H0V1.16895H14.0261V5.84431H4.67536V8.18199ZM4.67536 19.8704H8.18188C9.15591 19.8704 9.93514 19.4808 10.617 18.7989C11.2988 18.1171 11.6884 17.2405 11.6884 16.3639C11.6884 15.4872 11.2988 14.6106 10.617 13.9288C9.93514 13.247 9.05851 12.8573 8.18188 12.8573H4.67536V19.8704V19.8704Z"
        fill="white"
      />
      <path
        d="M31.4616 1.16895V5.84431H22.1109V10.5197H29.124V15.195H22.1109V19.8704H31.4616V24.5457H17.4355V1.16895H31.4616V1.16895Z"
        fill="white"
      />
      <path
        d="M32.6299 1.16895H40.8118C43.052 1.16895 45.0001 1.94817 46.5586 3.50663C48.117 5.06508 48.9936 7.01315 48.9936 9.25342C48.9936 11.4937 48.2144 13.4418 46.5586 15.0002C45.0001 16.5587 43.052 17.3379 40.8118 17.3379H37.3052V24.3509H32.6299V1.16895ZM43.2468 6.91574C42.565 6.23392 41.7858 5.84431 40.8118 5.84431H37.3052V12.8573H40.8118C41.7858 12.8573 42.565 12.4677 43.2468 11.7859C43.9287 11.1041 44.3183 10.2275 44.3183 9.35083C44.3183 8.4742 43.9287 7.59757 43.2468 6.91574Z"
        fill="white"
      />
      <path
        d="M68.7669 0.681641V24.4481H64.0915V11.5908L50.0654 24.9351V1.16866H54.7408V14.1233L68.7669 0.681641Z"
        fill="white"
      />
      <path
        d="M84.875 26.2499C92.1237 26.2499 97.9999 20.3737 97.9999 13.125C97.9999 5.87625 92.1237 0 84.875 0C77.6263 0 71.75 5.87625 71.75 13.125C71.75 20.3737 77.6263 26.2499 84.875 26.2499Z"
        fill="white"
      />
      <path
        d="M77.0467 11.5908H78.313C79.0922 11.5908 79.774 11.8831 80.3584 12.4675C80.9429 13.0519 81.2351 13.7337 81.2351 14.5129C81.2351 15.2922 80.9429 15.974 80.3584 16.5584C79.774 17.1428 79.0922 17.435 78.313 17.435H75.4883V9.15576H80.4559V10.7142H77.1441V11.5908H77.0467ZM77.0467 15.6818H78.313C78.6052 15.6818 78.8974 15.5844 79.1896 15.2922C79.3844 15.0974 79.5792 14.8052 79.5792 14.4155C79.5792 14.0259 79.4818 13.8311 79.1896 13.5389C78.8974 13.2467 78.7026 13.1493 78.313 13.1493H77.0467V15.6818Z"
        fill="#026DB0"
      />
      <path
        d="M85.0341 8.96094L88.7354 17.3376H86.9822L86.3977 16.0714H83.573L82.9886 17.3376H81.2354L85.0341 8.96094ZM85.7159 14.6103L84.9367 12.8571L84.2549 14.6103H85.7159Z"
        fill="#026DB0"
      />
      <path
        d="M89.1247 9.15576H91.9494C92.7287 9.15576 93.4105 9.44797 93.9949 10.0324C94.5793 10.6168 94.8715 11.2986 94.8715 12.0779C94.8715 12.8571 94.5793 13.5389 93.9949 14.1233C93.4105 14.7078 92.7287 15 91.9494 15H90.6832V17.435H89.0273V9.15576H89.1247ZM92.9235 11.1038C92.7287 10.8116 92.4365 10.7142 92.0468 10.7142H90.7806V13.1493H92.0468C92.3391 13.1493 92.6313 13.0519 92.9235 12.7597C93.1183 12.5649 93.3131 12.2727 93.3131 11.8831C93.3131 11.4934 93.1183 11.396 92.9235 11.1038Z"
        fill="#026DB0"
      />
    </svg>
  ),
  reviews__mark: (fill, width, height) => (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.02883 18C3.83158 18 3.63573 17.9357 3.46877 17.8096C3.15738 17.574 3.01191 17.1693 3.0968 16.7789L4.25462 11.4561L0.327461 7.86236C0.0386218 7.59955 -0.0716303 7.18309 0.0470757 6.80192C0.165429 6.42148 0.488084 6.15242 0.869916 6.11566L6.06586 5.62348L8.12014 0.606494C8.2716 0.238187 8.6168 0 9.00004 0C9.38328 0 9.72813 0.238187 9.87994 0.605759L11.9342 5.62348L17.1295 6.11566C17.512 6.15168 17.8347 6.42185 17.953 6.80192C18.0714 7.18236 17.9618 7.59955 17.6733 7.86236L13.7462 11.4554L14.904 16.7782C14.9889 17.1697 14.8434 17.5744 14.532 17.8089C14.2213 18.0437 13.8075 18.0618 13.4806 17.8566L9.00004 15.0627L4.51951 17.8585C4.36804 17.9522 4.19932 18 4.02883 18ZM9.00004 13.8593C9.17018 13.8593 9.3389 13.9071 9.49072 14.0008L13.7194 16.64L12.6264 11.616C12.5485 11.2584 12.6648 10.8853 12.9293 10.6434L16.6374 7.24999L11.7317 6.78501C11.3784 6.75119 11.0748 6.51962 10.9367 6.17999L9.00004 1.44566L7.0613 6.18072C6.92462 6.51815 6.62099 6.74972 6.26875 6.78354L1.362 7.24815L5.07006 10.6416C5.33566 10.8842 5.4519 11.2569 5.37299 11.6153L4.28104 16.6389L8.50972 14.0004C8.66083 13.9067 8.82956 13.8593 9.00004 13.8593ZM6.0264 5.72052C6.0264 5.72052 6.0264 5.72126 6.0257 5.72199L6.0264 5.72052ZM11.9723 5.71795L11.973 5.71942C11.973 5.71869 11.973 5.71868 11.9723 5.71795Z"
        fill={fill}
      />
    </svg>
  ),
  'close-balloon-button': () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
      <path
        d="M14 .7l-.7-.7L7 6.3.7 0 0 .7 6.3 7 0 13.3l.7.7L7 7.7l6.3 6.3.7-.7L7.7 7z"
        fillRule="evenodd"
        clipRule="evenodd"
        fill="currentColor"
      />
    </svg>
  ),
  'catalog-burger': (fill = '#000000') => (
    <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.375 9.5H16.625"
        stroke={fill}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.375 4.75H16.625"
        stroke={fill}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.375 14.25H16.625"
        stroke={fill}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  'arrow-bold': (fill = '#CBD2D8') => (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.96959 1.22704C3.82899 1.36768 3.75 1.55841 3.75 1.75729C3.75 1.95616 3.82899 2.14689 3.96959 2.28754L7.68209 6.00004L3.96959 9.71254C3.83297 9.85399 3.75738 10.0434 3.75908 10.2401C3.76079 10.4367 3.83967 10.6248 3.97873 10.7639C4.11778 10.903 4.30589 10.9818 4.50254 10.9835C4.69919 10.9853 4.88864 10.9097 5.03009 10.773L9.27284 6.53029C9.41344 6.38964 9.49243 6.19891 9.49243 6.00004C9.49243 5.80116 9.41344 5.61043 9.27284 5.46979L5.03009 1.22704C4.88944 1.08643 4.69871 1.00745 4.49984 1.00745C4.30097 1.00745 4.11024 1.08643 3.96959 1.22704Z"
        fill={fill}
      />
    </svg>
  ),
  'plane': () => (
    <svg width="20" height="9" viewBox="0 0 20 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M18.2103 2.75277L5.22516 8.78661C4.64895 9.05436 3.98966 9.07082 3.40115 8.83216L0.24252 7.55122C-0.0572114 7.42967 -0.0858892 7.0109 0.194398 6.84852L1.9625 5.8242C2.1168 5.73481 2.29682 5.70216 2.47212 5.73179L4.51567 6.07709L7.60179 4.51268L1.6987 2.40044C1.41328 2.29831 1.34975 1.91793 1.5861 1.72624L2.03609 1.36126C2.63849 0.872667 3.39954 0.630529 4.16928 0.682555L14.5456 1.38388L17.0531 0.406152C17.9532 0.0802346 19.6615 -0.315868 19.9463 0.40611C20.2549 1.18832 19.1748 2.36167 18.2103 2.75277Z" fill="black"/>
    </svg>
  ),
  'slider__winery__prev': () => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.75">
        <path fillRule="evenodd" clipRule="evenodd" d="M12.7071 0.292893C13.0976 0.683417 13.0976 1.31658 12.7071 1.70711L4.41421 10L12.7071 18.2929C13.0976 18.6834 13.0976 19.3166 12.7071 19.7071C12.3166 20.0976 11.6834 20.0976 11.2929 19.7071L2.29289 10.7071C1.90237 10.3166 1.90237 9.68342 2.29289 9.29289L11.2929 0.292893C11.6834 -0.0976311 12.3166 -0.0976311 12.7071 0.292893Z" fill="white"/>
      </g>
    </svg>
  ),
  'slider__winery__next': () => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.75">
        <path fillRule="evenodd" clipRule="evenodd" d="M7.29289 0.292893C6.90237 0.683417 6.90237 1.31658 7.29289 1.70711L15.5858 10L7.29289 18.2929C6.90237 18.6834 6.90237 19.3166 7.29289 19.7071C7.68342 20.0976 8.31658 20.0976 8.70711 19.7071L17.7071 10.7071C18.0976 10.3166 18.0976 9.68342 17.7071 9.29289L8.70711 0.292893C8.31658 -0.0976311 7.68342 -0.0976311 7.29289 0.292893Z" fill="white"/>
      </g>
    </svg>
  ),
  'slider__catalog__prev': () => (
    <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d_4399_17211)">
        <rect x="6" y="6" width="40" height="40" rx="20" fill="white" shapeRendering="crispEdges"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M28.7071 16.2929C29.0976 16.6834 29.0976 17.3166 28.7071 17.7071L20.4142 26L28.7071 34.2929C29.0976 34.6834 29.0976 35.3166 28.7071 35.7071C28.3166 36.0976 27.6834 36.0976 27.2929 35.7071L18.2929 26.7071C17.9024 26.3166 17.9024 25.6834 18.2929 25.2929L27.2929 16.2929C27.6834 15.9024 28.3166 15.9024 28.7071 16.2929Z" fill="#2E6BAB"/>
      </g>
      <defs>
        <filter id="filter0_d_4399_17211" x="0" y="0" width="56" height="56" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
          <feOffset dx="2" dy="2"/>
          <feGaussianBlur stdDeviation="4"/>
          <feComposite in2="hardAlpha" operator="out"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0.0280382 0 0 0 0 0.24629 0 0 0 0 0.395833 0 0 0 0.08 0"/>
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4399_17211"/>
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_4399_17211" result="shape"/>
        </filter>
      </defs>
    </svg>
  ),
  'slider__catalog__next': () => (
    <svg width="54" height="56" viewBox="0 0 54 56" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d_4399_17214)">
        <rect x="6" y="6" width="40" height="40" rx="20" fill="white" shapeRendering="crispEdges"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M23.2929 16.2929C22.9024 16.6834 22.9024 17.3166 23.2929 17.7071L31.5858 26L23.2929 34.2929C22.9024 34.6834 22.9024 35.3166 23.2929 35.7071C23.6834 36.0976 24.3166 36.0976 24.7071 35.7071L33.7071 26.7071C34.0976 26.3166 34.0976 25.6834 33.7071 25.2929L24.7071 16.2929C24.3166 15.9024 23.6834 15.9024 23.2929 16.2929Z" fill="#2E6BAB"/>
      </g>
      <defs>
        <filter id="filter0_d_4399_17214" x="0" y="0" width="56" height="56" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
          <feOffset dx="2" dy="2"/>
          <feGaussianBlur stdDeviation="4"/>
          <feComposite in2="hardAlpha" operator="out"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0.0280382 0 0 0 0 0.24629 0 0 0 0 0.395833 0 0 0 0.08 0"/>
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4399_17214"/>
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_4399_17214" result="shape"/>
        </filter>
      </defs>
    </svg>
  ),
  'winery-info-icon': () => (
    <svg width="11" height="38" viewBox="0 0 11 38" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.2716 37.6411C10.7006 37.0866 10.7346 36.5114 10.7232 35.9261C10.6892 32.6604 10.6442 29.3948 10.6442 26.129C10.6442 23.5719 10.1361 21.1279 9.02954 18.7759C7.85507 16.2702 7.32444 13.6617 7.38095 10.9404C7.41492 9.53338 7.27941 7.83893 7.43744 7.10991C7.53898 6.67873 7.3359 6.23719 7.32447 6.03168C7.31303 5.86746 7.22293 5.82617 6.99697 5.82617C6.03738 5.82617 5.07734 5.84666 4.11775 5.87755C4.0273 5.87755 3.89179 5.9699 3.86926 6.03168C3.79025 6.29864 3.71123 6.58609 3.71123 6.85341C3.71123 7.23352 3.82421 7.62373 3.82421 8.00353C3.80168 9.17415 3.68871 10.3552 3.70014 11.526C3.72267 13.9394 3.21461 16.2397 2.18703 18.4475C1.19346 20.5836 0.49337 22.771 0.447946 25.1021C0.380366 28.6246 0.334971 32.1469 0.278475 35.669C0.267038 36.3467 0.255946 37.0247 0.707512 37.6509C1.14799 38.1029 9.84209 38.1335 10.2715 37.6405L10.2716 37.6411ZM7.13243 6.85373V7.43903C7.13243 7.52129 7.06486 7.58275 6.97441 7.58275H6.80493C6.71448 7.58275 6.72592 7.52129 6.72592 7.43903V6.85373C6.72592 6.77146 6.71448 6.71 6.80493 6.71H6.97441C7.06486 6.71 7.13243 6.77147 7.13243 6.85373ZM6.56788 6.44304V6.29932C6.56788 6.21706 6.56788 6.1556 6.65833 6.1556H6.82781C6.91826 6.1556 6.98583 6.21706 6.98583 6.29932V6.44304C6.98583 6.5253 6.91825 6.58676 6.82781 6.58676H6.65833C6.56788 6.58676 6.56788 6.52499 6.56788 6.44304ZM9.44746 36.4913C9.33448 37.364 9.10887 37.313 8.65695 37.313C8.20538 37.313 8.20538 36.9537 8.20538 36.9537V24.3736C8.20538 22.1143 7.41487 20.2658 7.07625 18.5713C6.73731 16.8769 6.56784 12.9746 6.56784 12.9746V7.94262L6.61289 7.88116H6.90643L6.96292 7.94262C6.96292 7.94262 6.73696 13.7859 7.31295 16.2196C7.93398 18.8691 7.69693 17.9036 8.60009 20.2657C9.50361 22.6277 9.44709 25.0921 9.44709 25.0921C9.44744 25.0921 9.56007 35.6187 9.44744 36.4909L9.44746 36.4913Z" fill="black"/>
    </svg>
  ),
};
