import { createContext } from 'react';

import Filter from './Filter';

export const FilterContext = createContext();

const FilterWrapper = (props) => (
  <FilterContext.Provider value={{ ...props }}>
    <Filter />
  </FilterContext.Provider>
);

export default FilterWrapper;
