import { createSlice } from '@reduxjs/toolkit';

import { asyncActions as profileActions } from './profile';

const initialState = {
  title: '',
  message: '',
  isShowing: false,
  type: 'info',
};

const slice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    showNotification(state, { payload: { title, message } }) {
      return { ...state, message, isShowing: true, title };
    },
    hideNotification(state) {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(profileActions.updateUserData.rejected, (state, { payload: status }) => {
      if (status !== 400) {
        return {
          ...state,
          message: 'Что-то пошло не так',
          isShowing: true,
          title: 'Произошла ошибка',
        };
      }
    });
    builder.addCase(profileActions.updateUserData.fulfilled, (state, { payload: status }) => {
      return {
        ...state,
        message: 'Данные были обновлены',
        isShowing: true,
        title: 'Успешно',
      }
    });
  },
});

export const actions = { ...slice.actions };

export default slice.reducer;
