/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useRef, useEffect, useMemo, useCallback, useContext } from 'react';

import { debounce } from '../../utils/debounce';

import { FilterContext } from './FilterContext';

const RangeFilter = () => {
  const {
    props: {
      options: { min, max },
      filterName,
      type,
      onFiltersReset,
      restoreFilterState,
      onChange,
      currentQuery,
    },
  } = useContext(FilterContext);

  const pinValues = useMemo(
    () => ({
      min: Number(currentQuery[`${filterName}_min`]) || min,
      max: Number(currentQuery[`${filterName}_max`]) || max,
    }),
    [min, max, currentQuery]
  );

  const [rangeValue, setRangeValue] = useState(pinValues);
  const [inputValue, setInputValue] = useState(pinValues);

  const [values, setValues] = useState(pinValues);
  const range = useRef(null);

  useEffect(() => {
    if (onFiltersReset) {
      setRangeValue({ min, max });
      setInputValue({ min, max });
      setValues({ min, max });
      restoreFilterState(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [min, max, onFiltersReset]);

  useEffect(() => {
    const minKeyName = `${filterName}_min`;
    const maxKeyName = `${filterName}_max`;

    onChange([{ [minKeyName]: values.min }, { [maxKeyName]: values.max }], type);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  const getPercent = useCallback(
    (value) => Math.round(((value - min) / (max - min)) * 100),
    [min, max]
  );

  useEffect(() => {
    if (!range.current) return;
    const minPercent = getPercent(rangeValue.min);
    const maxPercent = getPercent(values.max);
    range.current.style.left = `${minPercent}%`;
    range.current.style.width = `${maxPercent - minPercent}%`;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rangeValue.min, values.max]);

  useEffect(() => {
    if (!range.current) return;
    const minPercent = getPercent(values.min);
    const maxPercent = getPercent(rangeValue.max);

    range.current.style.width = `${maxPercent - minPercent}%`;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rangeValue.max, values.min]);

  const updateValueMapping = useMemo(() => {
    return {
      min: (e) => [min, values.max, e].sort((a, b) => a - b)[1],
      max: (e) => [max, values.min, e].sort((a, b) => a - b)[1],
    };
  }, [min, max, values]);

  const delayedUpdateValue = useCallback(
    debounce((name, value) => {
      const averageValue = updateValueMapping[name](Number(value));
      setInputValue((state) => ({ ...state, [name]: averageValue }));
      setValues((state) => ({ ...state, [name]: averageValue }));
      setRangeValue((state) => ({ ...state, [name]: averageValue }));
    }, 1000),
    [values]
  );

  const inputChangeHandler = ({ target: { name, value } }) => {
    setInputValue((state) => ({ ...state, [name]: value }));
    delayedUpdateValue(name, value);
  };

  return (
    <>
      <div className="filter__footer">
        <div className="filter__input-wrapper filter__input-wrapper--left">
          <input
            className="filter__input filter__input--left"
            value={inputValue.min}
            type="text"
            name="min"
            onChange={inputChangeHandler}
          />
        </div>
        <div className="filter__input-wrapper filter__input-wrapper--right">
          <input
            className="filter__input filter__input--right"
            value={inputValue.max}
            type="text"
            name="max"
            onChange={inputChangeHandler}
          />
        </div>
      </div>
    </>
  );
};

export default RangeFilter;
