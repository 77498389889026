import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import axios, {
  apiRoutes,
  getFiltersInfoRequest,
  getViewsCountRequest,
  sentryErrorHandler,
} from '../api';

const productsInitialState = { results: [] };
const currentFiltersInitialState = {
  price_min: null,
  price_max: null,
  region: [],
  alcos: [],
  country: [],
  volumes: [],
};

const getBrandsInfo = createAsyncThunk('data/getBrandsInfo', () =>
  sentryErrorHandler(axios.get(apiRoutes.BRANDS))
);

const getProductTypesInfo = createAsyncThunk('data/getProductTypesInfo', () =>
  sentryErrorHandler(axios.get(apiRoutes.TYPES))
);

const getTagsCoreInfo = createAsyncThunk('data/getTagsCoreInfo', () =>
  sentryErrorHandler(axios.get(apiRoutes.TAGS_CORE))
);

const getCurrentTagPageInfo = createAsyncThunk('data/getCurrentTagPageInfo', (id) =>
  sentryErrorHandler(axios.get(apiRoutes.TAG_PAGE_INFO(id)))
);

const getProvidersInfo = createAsyncThunk('data/getProvidersInfo', () =>
  sentryErrorHandler(axios.get(apiRoutes.SUPPLIERS))
);

const getCountersInfo = createAsyncThunk('data/getCountersInfo', () =>
  sentryErrorHandler(axios.get(apiRoutes.COUNTERS))
);

const updateViewsCount = createAsyncThunk('data/updateViewsCount', async (id) => {
  if (!id) return 0;

  const viewsCounts = sessionStorage.getItem(id);
  if (viewsCounts) return viewsCounts;

  const { number_of_views: numberOfViews } = await sentryErrorHandler(getViewsCountRequest(id));

  sessionStorage.setItem(id, numberOfViews);
  return numberOfViews;
});

const getFiltersData = createAsyncThunk('data/getFiltersData', async (query) => {
  const { data } = await getFiltersInfoRequest(query);
  if (!data) throw Error();
  return data;
});

const slice = createSlice({
  name: 'data',
  initialState: {
    providerList: [],
    brandList: [],
    productTypes: [],
    tagsCore: [],
    currentTagPage: {},
    position: {},
    products: productsInitialState,
    currentType: {},
    counters: {
      positions_cnt: 0,
      prices_cnt: 0,
      suppliers_cnt: 0,
    },
    currentQuery: '',
    currentSupplierId: {},
    currentFilters: currentFiltersInitialState,
    currentPosition: { name: null, value: null, nameRu: null },
    requestStatus: null,
    numberOfViews: 0,
    similarProducts: [],
    reviews: [],
    reviewsCount: null,
    review: null,
    requestQuery: {},
    wineries: [],
  },
  reducers: {
    addReview(state, action) {
      state.reviews = [...state.reviews, action.payload];
    },
    setCurrentType(state, action) {
      state.currentType = action.payload;
    },
    setCurrentTagPage(state, action) {
      state.currentTagPage = action.payload;
    },
    setCurrentPosition(state, action) {
      state.currentPosition = { ...state.currentPosition, ...action.payload };
    },
    setCurrentQuery(state, action) {
      state.currentQuery = action.payload;
    },
    setReview(state, { payload }) {
      state.review = payload;
    },
    setFullPositionData(state, { payload: { position, similarProducts, reviews } }) {
      const reviewsCount = reviews.length;
      const review = reviews[reviewsCount - 1] || null;
      return { ...state, position, similarProducts, reviews, review, reviewsCount };
    },
    setCatalogData(state, { payload: { products, requestQuery } }) {
      return { ...state, products, requestQuery };
    },
    setWineriesData(state, action) {
      state.wineries = action.payload;
    },
    setWineryInfo(state, action) {
      state.wineries = {
        ...state.wineries,
        ...action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updateViewsCount.fulfilled, (state, { payload }) => {
      state.numberOfViews = payload;
    });
    builder.addCase(updateViewsCount.rejected, (state) => {
      state.numberOfViews = 0;
    });
    builder.addCase(getBrandsInfo.fulfilled, (state, { payload }) => {
      state.brandList = payload;
    });
    builder.addCase(getProductTypesInfo.fulfilled, (state, { payload }) => {
      state.productTypes = payload || [];
    });
    builder.addCase(getTagsCoreInfo.fulfilled, (state, { payload }) => {
      state.tagsCore = payload || [];
    });
    builder.addCase(getCurrentTagPageInfo.fulfilled, (state, { payload }) => {
      state.currentTagPage = payload || {};
    });
    builder.addCase(getProvidersInfo.fulfilled, (state, { payload }) => {
      state.providerList = payload || [];
    });
    builder.addCase(getCountersInfo.fulfilled, (state, { payload }) => {
      if (!payload) return state;
      state.counters = payload;
    });
    builder.addCase(getFiltersData.fulfilled, (state, { payload }) => {
      state.currentFilters = getFiltersValues(payload);
    });
    builder.addCase(getFiltersData.rejected, (state, { payload }) => {
      state.currentFilters = currentFiltersInitialState;
    });
  },
});

const filterTypesMapping = {
  types: (item) => ({ ...item, id: item.name, name: item.name_ru }),
  alcos: (item) => ({ ...item, id: item.item, name: `${item.item} %` }),
  volumes: (item) => ({ ...item, id: item.item, name: `${item.item} л.` }),
  grapes: (item) => ({ ...item, id: item.pk, name: item.item }),
  default: (item) => ({ ...item, id: item.pk, name: item.item }),
};

const getItemsWithNormalIdAndUnits = (items, type) => {
  return items.reduce(
    (acc, item) => (item.item ? [...acc, filterTypesMapping[type](item)] : acc),
    []
  );
};

const getFiltersValues = (data) => {
  const {
    alcos = [],
    countries = [],
    volumes = [],
    price_max = null,
    regions = [],
    price_min = null,
    subtypes = [],
    suppliers = [],
    brands = [],
    wineries = [],
    types = [],
    grapes = [],
    wine_sugar_contents = [],
    wine_color_contents = [],
    champagne_sugar_contents = [],
    champagne_color_contents = [],
    beer_filtrations = [],
    beer_colors = [],
    brandy_classes = [],
    liquor_sub_types = [],
  } = data || {};

  return {
    price_min,
    price_max,
    countries: getItemsWithNormalIdAndUnits(countries, 'default'),
    regions: getItemsWithNormalIdAndUnits(regions, 'default'),
    suppliers: getItemsWithNormalIdAndUnits(suppliers, 'default'),
    brands: getItemsWithNormalIdAndUnits(brands, 'default'),
    subtypes,
    wineries: getItemsWithNormalIdAndUnits(wineries, 'default'),
    alcos: getItemsWithNormalIdAndUnits(alcos, 'alcos'),
    volumes: getItemsWithNormalIdAndUnits(volumes, 'volumes'),
    types: getItemsWithNormalIdAndUnits(types, 'types'),
    grapes: getItemsWithNormalIdAndUnits(grapes, 'grapes'),
    wineSugarContents: getItemsWithNormalIdAndUnits(wine_sugar_contents, 'default'),
    wineColorContents: getItemsWithNormalIdAndUnits(wine_color_contents, 'default'),
    champagneSugarContents: getItemsWithNormalIdAndUnits(champagne_sugar_contents, 'default'),
    champagneColorContents: getItemsWithNormalIdAndUnits(champagne_color_contents, 'default'),
    beerFiltrations: getItemsWithNormalIdAndUnits(beer_filtrations, 'default'),
    beerColors: getItemsWithNormalIdAndUnits(beer_colors, 'default'),
    brandyClasses: getItemsWithNormalIdAndUnits(brandy_classes, 'default'),
    liquorSubTypes: getItemsWithNormalIdAndUnits(liquor_sub_types, 'default'),
  };
};

export const commonRequests = () => async (dispatch) => {
  await dispatch(asyncActions.getBrandsInfo());
  await dispatch(asyncActions.getProductTypesInfo());
  await dispatch(asyncActions.getTagsCoreInfo());
  await dispatch(asyncActions.getProvidersInfo());
};

export const actions = { ...slice.actions };

export const asyncActions = {
  getProductTypesInfo,
  getTagsCoreInfo,
  getCurrentTagPageInfo,
  getProvidersInfo,
  getBrandsInfo,
  updateViewsCount,
  commonRequests,
  getCountersInfo,
  getFiltersData,
};

export default slice.reducer;
