import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { createWrapper, HYDRATE } from 'next-redux-wrapper';

import beriBarData from './beriBarData';
import { actions as beriBarDataActions } from './beriBarData';
import { asyncActions as asyncBeriBarDataActions } from './beriBarData';
import config from './config';
import { actions as configActions } from './config';
import { asyncActions as asyncConfigActions } from './config';
import data from './data';
import { actions as dataActions } from './data';
import { asyncActions as asyncDataActions } from './data';
import notification from './notification';
import { actions as notificationActions } from './notification';
import profile from './profile';
import { asyncActions as asyncProfileActions } from './profile';
import { actions as profileActions } from './profile';
import user from './user';
import { asyncActions as asyncUserActions } from './user';
import { actions as userActions } from './user';
import window from './window';
import { actions as windowActions } from './window';

const appReducer = combineReducers({
  user,
  window,
  data,
  beriBarData,
  notification,
  config,
  profile,
});

const reducer = (state, action) => {
  if (action.type === HYDRATE) {
    const nextState = {
      ...state,
      ...action.payload,
    };
    nextState.user = state.user;
    return nextState;
  } else {
    return appReducer(state, action);
  }
};

export const store = configureStore({ reducer });

export const makeStore = () => store;

export const wrapper = createWrapper(makeStore);

const generalRequests = (token, city) => async (dispatch) => {
  await dispatch(asyncBeriBarDataActions.getBeriBarData());
  await dispatch(asyncBeriBarDataActions.getBeriBarPhones(city));
  await dispatch(asyncDataActions.getProductTypesInfo());
  await dispatch(asyncDataActions.getTagsCoreInfo());
  await dispatch(asyncDataActions.getProvidersInfo());
  await dispatch(asyncDataActions.getBrandsInfo());
  await dispatch(asyncConfigActions.getConfig());
  await dispatch(asyncProfileActions.getUserData(token));
  await dispatch(asyncDataActions.getCountersInfo());
};

export const actions = {
  ...userActions,
  ...windowActions,
  ...dataActions,
  ...beriBarDataActions,
  ...notificationActions,
  ...configActions,
  ...profileActions,
};
export const asyncActions = {
  ...asyncDataActions,
  ...asyncBeriBarDataActions,
  ...asyncUserActions,
  ...asyncConfigActions,
  ...asyncProfileActions,
  generalRequests,
};
