import { createSlice } from '@reduxjs/toolkit';

const slice = createSlice({
  name: 'window',
  initialState: {
    isModalShowing: false, //  Это попап с предупреждение +18
    pageTitle: 'Берибар - главная',
    isClient: false,
    size: {
      screenWidth: null,
      screenHeight: null,
    },
    showMenu: false, // Это мобильное меню
    showFilterMenu: false,
    isBodyOverflowHidden: false,
    imagesSrcs: [],
    isDesktop: true,
    isSearchOpen: false,
    showBlur: false,
    seo: {
      host: '',
      url: '',
    },
  },
  reducers: {
    setWindowSize(state, action) {
      state.size = action.payload;
    },
    setIsDesktop(state, { payload }) {
      return { ...state, isDesktop: payload };
    },
    changeModalState(state, { payload: { isModalShowing } }) {
      state.isModalShowing = isModalShowing;
    },
    setShowMenu(state, { payload }) {
      state.showMenu = payload;
    },
    setShowBlur(state, { payload }) {
      state.showBlur = payload;
    },
    setIsClient(state) {
      state.isClient = true;
    },
    setBodyOverflowHidden(state, { payload }) {
      state.isBodyOverflowHidden = payload;
    },
    setIsSearchOpen(state, { payload }) {
      state.isSearchOpen = payload;
    },
    setShowFilterMenu(state, { payload }) {
      state.showFilterMenu = payload;
    },
    setPageTitle(state, { payload }) {
      state.pageTitle = payload;
    },
    addSrcToImageSrcs(state, { payload }) {
      state.imagesSrcs = [...new Set([...state.imagesSrcs, { src: payload, loaded: false }])];
    },
    updateImageSrcs(state, { payload }) {
      state.imagesSrcs = state.imagesSrcs.map((image) =>
        image.src === payload ? { image, loaded: true } : image
      );
    },
    setSeoData(state, { payload }) {
      state.seo = payload;
    },
  },
});

export const actions = { ...slice.actions };

export default slice.reducer;
