import cn from 'classnames';

import Icon from '../Icon';

const OptionsItem = ({ data, onCLick }) => {
  const onClickHandler = () => onCLick(data.id);

  const filterClassnames = cn('filter__option-checkbox', {
    'filter__option-checkbox--checked': data.isSet,
  });

  return (
    <li onClick={onClickHandler} className="filter__option-item">
      <span className={filterClassnames}>{data.isSet && <Icon name="option__mark" />}</span>
      <p className="filter__option-label" htmlFor="1">
        {data.name}
      </p>
    </li>
  );
};

export default OptionsItem;
