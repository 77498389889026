import { useEffect, useState } from 'react';

const OptionsFilterSearch = ({ active, setSearchValue }) => {
  const [value, setValue] = useState('');
  const [inputTimeout, setInputTimeout] = useState('');

  const handleChange = ({ target: { value } }) => {
    setValue(value);
  };

  useEffect(() => {
    setValue('');
  }, [active]);

  useEffect(() => {
    throttledHandler(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const throttledHandler = (value) => {
    if (!value) {
      clearTimeout(inputTimeout);
      setSearchValue('');
      return;
    }

    clearTimeout(inputTimeout);

    const timeout = setTimeout(() => {
      setSearchValue(value);
    }, 500);

    setInputTimeout(timeout);
  };

  return active ? (
    <div className="filter__search-input">
      <input type="text" value={value} onChange={handleChange} />
    </div>
  ) : null;
};

export default OptionsFilterSearch;
