import * as Sentry from '@sentry/nextjs';
import axios from 'axios';

import config from '../configs';
import { actions, store } from '../redux';
import { geObjectaWithSnakeCasedKeys } from '../utils';

export const sentryErrorHandler = async (request) => {
  try {
    const { data } = await request;
    return data;
  } catch (error) {
    const status = error?.response?.status;
    if (status >= 500) Sentry.captureException(error);
    return null;
  }
};

const errorWrapper = async (promise) => {
  try {
    return await promise;
  } catch (error) {
    const status = error?.response?.status;
    if (status) {
      if (status === 404 || status === 400) return { data: null };
      if (status <= 500) {
        Sentry.captureException(error);
        return { data: null };
      }
    }
    throw Error;
  }
};

export const MESSAGE_ON_UNAUTHORIZED_REQUEST = 'Учетные данные не были предоставлены.';

export const pageRoutes = {
  SUPPLIER: '/supplier',
  SUPPLIERS: '/suppliers',
  POSITIONS: '/positions/',
  SEARCH: '/search/',
  CONTACTS: '/info/contacts/',
  FOR_CLIENTS: '/info/for_clients/',
  ABOUT: '/info/about/',
  AGREEMENT: '/info/agreement/',
  PRIVACY_POLICY: '/info/privacy_policy/',
  PROFILE: '/profile/',
  INDEX: '/',
};

export const getPageRoutes = {
  BRAND: (brand) => `/brands?brand=${brand}`,
  POSITION: (position) => `/positions/${position}`,
  SUPPLIER: (supplier) => `/supplier/${supplier}`,
  supplier: (supplier) => getPageRoutes.SUPPLIER(supplier),
  brand: (brand) => getPageRoutes.BRAND(brand),
  POSITIONS: () => '/positions',
  SUPPLIERS: () => '/suppliers',
  TYPE: (type) => `/types/${type}`,
};

export const apiRoutes = {
  AUTH: '/auth/login',
  TYPES: '/types',
  TAGS_CORE: '/core_for_tag_pages',
  TAG_PAGE_INFO: (id) => `/tag_pages/${id}`,
  POSITIONS: '/positions/',
  FILTERS: '/filters',
  SUPPLIERS: '/suppliers',
  COUNTERS: '/counters',
  BRANDS: '/brands/',
  REVIEWS: '/reviews/',
  INSTANT_SEARCH: '/positions/',
  SEARCH: '/search',
  SUPPLIERPOSITIONS: '/supplier/positions/?supplier=',
  SEND_ORDER: '/send',
  NUMBER_OF_VIEWS: '/number_of_views/',
  SEND_CODE: '/phone_auth/send_code/',
  TOKEN: '/phone_auth/token/',
  MEMBER: '/member',
  CITIES: '/cities',
  BERIBAR_DATA: '/beribar',
  SITE_MAP: '/positions_for_sitemap/',
  WINERIES: '/winery_pois',
  WINERY_INFO: (id) => `/winery_pois/${id}`,
};

const instance = axios.create({
  baseURL: `${config.ORIGIN}${config.API_BASE_URL}`,
});

const instanceWithoutHeaders = axios.create({
  baseURL: `${config.ORIGIN}${config.API_BASE_URL}`,
});

instance.interceptors.request.use(
  (config) => {
    const {
      user: { remoteAddress },
    } = store.getState();

    config.headers['X-Forwarded-For'] = remoteAddress;

    return config;
  },
  (error) => Promise.reject(error)
);

const logoutWrapper = async (promise) => {
  try {
    return await promise;
  } catch (error) {
    const status = error?.response?.status;
    const { detail } = error?.response?.data;
    if (status === 401 && detail === MESSAGE_ON_UNAUTHORIZED_REQUEST) {
      store.dispatch(actions.userLogout());
      store.dispatch(
        actions.showNotification({
          title: 'Произошла ошибка',
          message: 'Необходимо авторизоваться',
        })
      );
    } else {
      throw error;
    }
  }
};

const notificationWrapper = async (promise, message = 'Что-то пошло не так') => {
  try {
    return await promise;
  } catch (error) {
    const title = 'Произошла ошибка';
    const status = error?.response?.status;
    Sentry.captureException(error);
    if (!status) {
      store.dispatch(actions.showNotification({ title, message }));
      return;
    }
    if (status === 404) {
      return { data: null, error: message };
    } else {
      store.dispatch(actions.showNotification({ title, message }));
      return null;
    }
  }
};

export const sendOrderRequest = ({ userData, offers }) => {
  const userDataWithSnakeCasedKeys = geObjectaWithSnakeCasedKeys(userData);

  return notificationWrapper(
    instanceWithoutHeaders.post(apiRoutes.SEND_ORDER, {
      ...userDataWithSnakeCasedKeys,
      offers,
    })
  );
};

export const getProductInfoRequest = (id) => errorWrapper(instance.get(getPageRoutes.POSITION(id)));

export const getProductListRequest = (query) => {
  const myQuery = new URLSearchParams(query).toString();

  return errorWrapper(
    instance.get(query ? `${apiRoutes.POSITIONS}?${myQuery}` : apiRoutes.POSITIONS)
  );
};

export const getWineryInfoRequest = (id) => errorWrapper(instance.get(apiRoutes.WINERY_INFO(id)));

export const getWineriesListRequest = () => errorWrapper(instance.get(apiRoutes.WINERIES));

export const getFiltersInfoRequest = (query) => {
  const myQuery = new URLSearchParams(query).toString();

  return errorWrapper(
    instanceWithoutHeaders.get(query ? `${apiRoutes.FILTERS}?${myQuery}` : apiRoutes.FILTERS)
  );
};

export const getSimilarProductsRequest = (similarProductsProperties) => {
  const myQuery = new URLSearchParams(similarProductsProperties).toString();
  return sentryErrorHandler(instance.get(`${apiRoutes.POSITIONS}?${myQuery}`));
};

export const getReviewsRequest = (id) =>
  sentryErrorHandler(instance.get(`${apiRoutes.REVIEWS}?position=${id}`));

export const sendCodeRequest = (number) =>
  notificationWrapper(instanceWithoutHeaders.post(apiRoutes.SEND_CODE, number));

export const getSitemapData = () =>
  sentryErrorHandler(instanceWithoutHeaders.get(apiRoutes.SITE_MAP));

export const addReviewRequest = (data, token) => {
  const headers = token ? { Authorization: `Token ${token}` } : {};

  return notificationWrapper(
    logoutWrapper(instanceWithoutHeaders.post(apiRoutes.REVIEWS, data, { headers }))
  );
};

export const sendTokenRequest = (data) =>
  notificationWrapper(instanceWithoutHeaders.post(apiRoutes.TOKEN, data), 'Ввели неправльный код');

export const instantSearch = (value) =>
  notificationWrapper(instanceWithoutHeaders.get(`${apiRoutes.INSTANT_SEARCH}?${value}`));

export const getViewsCountRequest = (id) =>
  instanceWithoutHeaders.post(apiRoutes.NUMBER_OF_VIEWS, { id });

export default instance;

const configInstance = axios.create({
  baseURL: `${config.ORIGIN}/api`,
});

export { configInstance, instanceWithoutHeaders };
