const pageRouteMapping = {
  default: (query, name, value, _tagLink) => {
    return query ? `/${name}/${value}?${query}` : `/${name}/${value}`;
  },
  search: (_query, _name, value, _tagLink) => {
    return `/search?search=${value}`;
  },
  tags: (query, name, value, tagLink) => {
    return query ? `${tagLink}?${query}` : tagLink;
  },
};

export const getHrefWithoutFiltersData = (currentQuery, { name, value }, { link: tagLink }) => {
  const onlySortingAndPageSizeData = {
    ...(currentQuery.page_size && { page_size: currentQuery.page_size }),
  };
  const params = new URLSearchParams();

  Object.entries(onlySortingAndPageSizeData).forEach(([key, value]) => {
    params.append(key, value);
  });
  const query = params.toString();

  const path = pageRouteMapping[name]
    ? pageRouteMapping[name](query, name, value, tagLink)
    : pageRouteMapping.default(query, name, value, tagLink);

  return path;
};
