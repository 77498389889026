import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { sentryErrorHandler, configInstance } from '../api';

export const getConfig = createAsyncThunk('config/getConfig', async () => {
  const data = await sentryErrorHandler(configInstance.get('/config'));
  return data;
});

const slice = createSlice({
  name: 'config',
  initialState: {
    TAG_MANAGER_FOR_BODY: '',
    TAG_MANAGER_FOR_HEAD: '',
  },
  extraReducers: (builder) => {
    builder.addCase(getConfig.fulfilled, (state, { payload }) => {
      if (!payload) return state;

      const data = payload.reduce(
        (acc, { key, value, name }) => ({ ...acc, [key]: { name, value } }),
        {}
      );
      return data;
    });
  },
});

export const actions = { ...slice.actions };
export const asyncActions = { getConfig };
export default slice.reducer;
