import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/nextjs';
import Cookies from 'js-cookie';

import { apiRoutes, MESSAGE_ON_UNAUTHORIZED_REQUEST, instanceWithoutHeaders } from '../api';

const getUserData = createAsyncThunk('profile/get', async (token, { rejectWithValue }) => {
  try {
    const headers = token ? { Authorization: `Token ${token}` } : {};
    const { data } = await instanceWithoutHeaders.get(apiRoutes.MEMBER, { headers });
    return data;
  } catch (error) {
    const status = error?.response?.status;
    const { detail } = error?.response?.data;
    if (!(status === 401 && detail === MESSAGE_ON_UNAUTHORIZED_REQUEST)) {
      Sentry.captureException(error);
    }
    return rejectWithValue(status);
  }
});

const updateUserData = createAsyncThunk(
  'profile/update',
  async ({ values, token }, { rejectWithValue }) => {
    const headers = token ? { Authorization: `Token ${token}` } : {};
    try {
      const { data } = await instanceWithoutHeaders.put(apiRoutes.MEMBER, values, { headers });
      return data;
    } catch (error) {
      const status = error?.response?.status;
      const { detail } = error?.response?.data;
      if (!(status === 401 && detail === MESSAGE_ON_UNAUTHORIZED_REQUEST)) {
        Sentry.captureException(error);
      }
      return rejectWithValue(status);
    }
  }
);

const profileInitData = {
  username: '',
  avatarPath: null,
  phone: '',
  email: '',
  first_name: '',
  last_name: '',
  id: '',
  isAuthorized: false,
  token: null,
  city: null,
  company_name: '',
};

const slice = createSlice({
  name: 'profile',
  initialState: profileInitData,
  reducers: {
    userLogout(state) {
      Cookies.remove('token');
      state.token = null;
      state.isAuthorized = false;
      state = profileInitData;
    },
    userAuth(state, { payload }) {
      Cookies.set('token', payload);
      state.isAuthorized = true;
      state.token = payload;
    },
    setToken(state, { payload }) {
      state.isAuthorized = !!payload;
      state.token = payload;
    },
    setCity(state, { payload }) {
      state.city = payload;
    },
    setuserData(state, { payload }) {
      return { ...state, ...payload };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUserData.fulfilled, (state, { payload }) => {
      return { ...state, ...payload, isAuthorized: true };
    });
    builder.addCase(getUserData.rejected, (_, { payload: status }) => {
      Cookies.remove('token');
      if (status === 401) return profileInitData;
    });
    builder.addCase(updateUserData.fulfilled, (state, { payload }) => {
      return { ...state, ...payload };
    });
    builder.addCase(updateUserData.rejected, (_, { payload: status }) => {
      Cookies.remove('token');
      if (status === 401) return profileInitData;
    });
  },
});

export const actions = { ...slice.actions };

export const asyncActions = { updateUserData, getUserData };

export default slice.reducer;
