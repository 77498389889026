export const MAX_MOBILE_SCREEN_WIDTH = 991;
export const MAIN_PAGE_LIST_SIZE = 12;
export const DEFAULT_PAGE_SIZE = 36;
export const TABLET_MAX_SCREEN_WIDTH = 992;
export const MOBILE_MAX_SCREEN_WIDTH = 768;
export const DEFAULT_OPTIONS_AMOUNT_TO_SHOW = 8;

export const DEFAULT_IMG_URL_WEBP = '/images/placeholder.webp';
export const DEFAULT_IMG_URL = '/images/placeholder.png';

export const SUPPLIERS__FROMS = ['поставщик', 'поставщика', 'поставщиков'];

export const searchSystemMapping = {
  google: 'https://google.com/search?q=',
  yandex: 'https://yandex.ru/search/?text=',
};
