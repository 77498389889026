import {
  getExtraFilters,
  getFilters,
  getSubtypes,
  standartFilters,
} from '../components/Filters/helpers';
import { initialPosition } from '../redux/user';

export const phoneCharactersPattern = '^[0-9-+]{3,15}$';
export const emailCharactersPattern = '^[a-zA-Z0-9_\\.\\+\\-@]*$';

export const renderComponent = (mapping, type, props) => {
  const Component = mapping[type];
  return <Component props={props} />;
};

const camelToSnakeCase = (str) => str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);

export const getSuppliersCounts = (offers) =>
  offers && [...new Set(offers.map((item) => item.supplier.id))].length;

export const geObjectaWithSnakeCasedKeys = (obj) =>
  Object.entries(obj).reduce(
    (acc, [key, value]) => ({ ...acc, [camelToSnakeCase(key)]: value }),
    {}
  );

export const getWordDeclination = (number, textForms) => {
  const modulo1 = Math.abs(number) % 100;
  const modulo2 = modulo1 % 10;
  if (modulo1 > 10 && modulo1 < 20) return textForms[2];
  if (modulo2 > 1 && modulo2 < 5) return textForms[1];
  if (modulo2 === 1) return textForms[0];
  return textForms[2];
};

export const getNames = (changeMainName, name, name_ru) =>
  changeMainName
    ? { mainName: name_ru ? name_ru : name, secondaryName: name }
    : { mainName: name, secondaryName: name_ru ? name_ru : name };

export const capitalizeFirstLetter = (string) =>
  string ? string.charAt(0).toUpperCase() + string.slice(1) : string;

const getParsedCookies = (cookies) =>
  cookies
    ? cookies.split('; ').reduce((acc, item) => {
      const [key, ...values] = item.split('=');
      const value = values.join('');
      return { ...acc, [key]: value };
    }, {})
    : {};

const getCityFromCookies = (cookies) => {
  const parsedCookies = getParsedCookies(cookies);
  return parsedCookies.city || '';
};

const getTokenFromCookies = (cookies) => {
  const parsedCookies = getParsedCookies(cookies);
  return parsedCookies.token ? parsedCookies.token : '';
};

export const getFormatDate = (string) => {
  const date = new Date(string);

  return `${date.getFullYear()}.${date.getMonth() + 1}.${date.getDate()}`;
};

export const getUserDataFromRequest = (context) => {
  const token = getTokenFromCookies(context.req.headers.cookie);
  const city = getCityFromCookies(context.req.headers.cookie) || initialPosition.code;
  const {
    req: {
      socket: { remoteAddress },
    },
  } = context;

  return { city, token, remoteAddress };
};

export const getDate = (time) => {
  const date = new Date();

  if (time) {
    date.setHours(time.slice(0, 2));
    date.setMinutes(time.slice(3, 5));
    date.setSeconds(time.slice(7, 6));
  }

  return date;
};

export const findCurrentTagPage = (tagsCore, pathname) => {
  const addLeadingSlash = (path) => (path[0] === '/' ? path : `/${path}`);

  for (const alcoholType of tagsCore) {
    const found = alcoholType.tag_pages.find((item) => addLeadingSlash(item.link) === pathname);
    if (found) {
      return { ...found, alcoholType: alcoholType.alcohol_type };
    }
  }

  return null;
};

export const getQueryFromTag = (currentTagPage, position, currentFilters) => {
  const subtypes = getSubtypes(currentFilters.subtypes);
  const extraFilters = getExtraFilters(position.value);

  const allFilters = standartFilters
    .filter(getFilters(position, 'standartFilters'))
    .concat(subtypes.filter(getFilters(position, 'subtypes')))
    .concat(extraFilters);

  const filterFromTag = allFilters.find(
    (item) => item.filterName === currentTagPage.alcohol_sub_type
  );
  const filterOption = filterFromTag
    ?.getOptions(currentFilters)
    .find((option) => option.item === currentTagPage.filter);
  const filterValueFromTag =
    currentTagPage[currentTagPage.alcohol_sub_type]?.id || filterOption?.id;

  return {
    [currentTagPage.alcohol_sub_type]: filterValueFromTag ? String(filterValueFromTag) : undefined,
  };
};
