/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, useContext } from 'react';

import { DEFAULT_OPTIONS_AMOUNT_TO_SHOW } from '../../constants';
import { useGroupSort } from '../../hooks/useGroupSort';

import { FilterContext } from './FilterContext';
import OptionsFilterSearch from './OptionsFilterSearch';
import OptionsFilterShowMore from './OptionsFilterShowMore';
import OptionsItem from './OptionsItem';

const OptionsFilter = () => {
  const {
    props: {
      filterName,
      type,
      options,
      onFiltersReset,
      restoreFilterState,
      onChange,
      currentQuery,
    },
  } = useContext(FilterContext);

  const selectedOptionsFromQuery = currentQuery[filterName]
    ? currentQuery[filterName].split(', ')
    : [];

  const [values, setValues] = useState([]);
  const [showAllOptions, setShowAllOptions] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [searchResult, setSearchResult] = useState([]);

  useEffect(() => {
    const filteredValues = values.map((item) => ({
      ...item,
      filter: item.name.toLowerCase().includes(searchValue.toLowerCase()),
    }));
    const FilteredSearchResults = [...filteredValues].filter((item) => item.filter);

    setSearchResult(FilteredSearchResults);

    setValues(filteredValues);
  }, [searchValue]);

  useEffect(() => {
    if (options?.length) {
      setValues(options);
      const filteredValues = options.map((item) => ({
        ...item,
        filter: item.name.toLowerCase().includes(searchValue.toLowerCase()),
      }));
      const FilteredSearchResults = [...filteredValues].filter((item) => item.filter);
      setSearchResult(FilteredSearchResults);
    }
  }, [options]);

  const onCLickHandler = (id) => {
    setValues((state) =>
      state.map((item) => (item.id === id ? { ...item, isSet: !item.isSet } : item))
    );
  };

  useEffect(() => {
    setShowAllOptions(false);
  }, [currentQuery]);

  useEffect(() => {
    let maxIndex = DEFAULT_OPTIONS_AMOUNT_TO_SHOW;
    setValues(
      options?.map((item, index) => {
        const isOptionInQuery = !!selectedOptionsFromQuery.find(
          (listItem) => listItem === item.id.toString()
        );
        if (isOptionInQuery) maxIndex += 1;
        return {
          ...item,
          isSet: isOptionInQuery,
          show: index < maxIndex - selectedOptionsFromQuery.length || isOptionInQuery,
        };
      })
    );
    restoreFilterState(false);
  }, [options, onFiltersReset]);

  useEffect(() => {
    if (values) {
      const checkedValues = values
        .filter((item) => item.isSet)
        .map((item) => item.id)
        .join(', ');
      onChange({ [filterName]: checkedValues }, type);
    }
  }, [values]);

  const sortedByNameValues = [...values].sort((prev, next) => (prev.name < next.name ? -1 : 1));

  const filteredGroups = useGroupSort(sortedByNameValues);

  return (
    <>
      <OptionsFilterSearch active={showAllOptions} setSearchValue={setSearchValue} />
      {!!searchResult.length ? null : <div>Ничего не найдено</div>}
      <ul className="filter__options-list">
        {showAllOptions && !!searchResult.length
          ? filteredGroups?.map((item) => (
            <div>
              {filterName !== 'alco' && filterName !== 'volume' ? (
                <div className="filter__option-title">{item.group}</div>
              ) : null}
              {item.children.map(
                (item) =>
                  (searchValue ? item.filter : showAllOptions ? true : item.show) && (
                    <OptionsItem onCLick={onCLickHandler} key={item.id} data={item} />
                  )
              )}
            </div>
          ))
          : sortedByNameValues?.map(
            (item) =>
              (searchValue ? item.filter : showAllOptions ? true : item.show) && (
                <OptionsItem onCLick={onCLickHandler} key={item.id} data={item} />
              )
          )}
      </ul>
      <OptionsFilterShowMore
        onChange={setShowAllOptions}
        disabled={showAllOptions}
        active={options?.length >= DEFAULT_OPTIONS_AMOUNT_TO_SHOW}
      />
    </>
  );
};

export default OptionsFilter;
