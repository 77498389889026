export const useGroupSort = (arr) => {

  const isDigit = (firstSymbol) => (
    firstSymbol >= '0' && firstSymbol <= '9'
  );

  const separatedNumbersArray = [...arr].filter((item) => isDigit(item.name[0]));
  const separatedWordsArray = [...arr].filter((item) => !isDigit(item.name[0]));

  const groupedNumbersArray = getObjectValues(separatedNumbersArray);
  const groupedWordsArray = getObjectValues(separatedWordsArray);
  const finalResult = groupedWordsArray.concat(groupedNumbersArray);

  return finalResult

  function getObjectValues (arr) {
    const obj = arr.reduce((acc, el) => {
      const group = el.name[0].toUpperCase();
      if (!acc[group]) {
        acc[group] = {group, children: [el]};
      }
      else  {
        acc[group].children.push(el);
      }
      return acc;
    }, {});

    return Object.values(obj);
  };
};
