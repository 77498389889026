import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import axios, { sentryErrorHandler, apiRoutes } from '../api';

const convertPhone = (phone) => phone.replace(/(.)(...)(...)(..)(..)/, '$1 $2 $3-$4-$5');

export const getBeriBarData = createAsyncThunk('beriBarData/getBeriBarData', async () => {
  const data = await sentryErrorHandler(axios.get(apiRoutes.BERIBAR_DATA));
  return data;
});

export const getBeriBarPhones = createAsyncThunk('beriBarData/getBeriBarPhones', async (city) => {
  const phones = await sentryErrorHandler(axios.get(apiRoutes.CITIES));
  if (!phones) throw new Error();
  return { phones, city };
});

const slice = createSlice({
  name: 'beriBarData',
  initialState: {
    opening_hours: '',
    phone: '',
    email: '',
    description: '',
    info: '',
    banner: '',
    banners: [],
  },
  extraReducers: (builder) => {
    builder.addCase(getBeriBarData.fulfilled, (state, { payload }) => {
      return { ...state, ...payload };
    });
    builder.addCase(getBeriBarPhones.fulfilled, (state, { payload: { phones, city } }) => {
      const [phone] = phones
        .filter(({ code }) => code === city)
        .map(({ phone }) => (phone ? convertPhone(phone.toString()) : ''));
      state.phone = phone;
    });
    builder.addCase(getBeriBarPhones.rejected, (state) => {
      state.phone = '';
    });
  },
});

export const actions = { ...slice.actions };
export const asyncActions = { getBeriBarData, getBeriBarPhones };
export default slice.reducer;
