const getParams = (filteredFilters) => {
  const params = new URLSearchParams();

  filteredFilters.forEach(([key, value]) => {
    params.append(key, value);
  });

  return params.toString();
};

const pageRouteMapping = {
  brand: (name, filteredFilters, _value, _tagLink) => {
    const queryWithoutBrands = filteredFilters.filter(([item]) => item !== 'brand');

    const brand = filteredFilters.reduce(
      (acc, item) => (item[0] === 'brand' ? item[1].replace(/ /g, '') : acc),
      ''
    );
    const query = getParams(queryWithoutBrands);
    const href = `/${name}/${brand}`;
    return query ? `${href}?${query}` : href;
  },
  search: (name, filteredFilters, value, _tagLink) => {
    const query = getParams([...filteredFilters, ['search', value]]);
    return `/search?${query}`;
  },
  tags: (name, filteredFilters, value, tagLink) => {
    const query = getParams(filteredFilters);
    return query ? `${tagLink}?${query}` : value;
  },
  default: (name, filteredFilters, value, _tagLink) => {
    const query = getParams(filteredFilters);
    return query ? `/${name}/${value}?${query}` : `/${name}/${value}`;
  },
};

export const getHrefWithFiltersData = (
  currentQuery,
  { name, value },
  filters,
  currentFilters,
  { link: tagLink }
) => {
  const onlySortingAndPageSizeData = {
    ...(currentQuery.page_size && { page_size: currentQuery.page_size }),
    ...(currentQuery.ordering && { ordering: currentQuery.ordering }),
  };
  const mergedData = { ...onlySortingAndPageSizeData, ...filters };

  const filteredFilters = Object.entries(mergedData).filter(
    ([key, value]) => !!value && currentFilters[key] !== value
  );

  const params = new URLSearchParams();

  filteredFilters.forEach(([key, value]) => {
    if (key !== name) params.append(key, value);
  });

  const path = pageRouteMapping[name]
    ? pageRouteMapping[name](name, filteredFilters, value, tagLink)
    : pageRouteMapping.default(name, filteredFilters, value, tagLink);

  return path;
};
