import cn from 'classnames';

const OptionsFilterShowMore = ({ disabled, onChange, active }) => {
  const onClickHandler = () => {
    if (active) {
      onChange(!disabled);
    }
  };

  const classnames = cn('filter__show-more', {
    'filter__show-more--disabled': !active,
    'filter__show-more--active': active,
  });

  return (
    <div onClick={onClickHandler} className={classnames}>
      <ul>
        <li></li>
        <li></li>
        <li></li>
      </ul>
      <span className="filter__show-more-btn" onClick={onClickHandler}>
        {!disabled ? 'показать ещё' : 'скрыть'}
      </span>
    </div>
  );
};

export default OptionsFilterShowMore;
