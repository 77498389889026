import cn from 'classnames';
import { useState, useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';

import Icon from '../Icon';

import { FilterContext } from './FilterContext';
import { filterTypeMapping } from './helpers';

const Filter = () => {
  const {
    props: { name, type, options, currentQuery, filterName },
  } = useContext(FilterContext);

  const isFiltersApplied = filterTypeMapping[type].checkIfFilterWasApplied(
    currentQuery,
    filterName
  );

  const { products } = useSelector((state) => state.data);
  const [isOpened, setIsOpened] = useState(isFiltersApplied);
  const [isFilterDisabled, setIsFilterDisabled] = useState(false);

  const onClickHandler = () => setIsOpened((state) => !isFilterDisabled && !state);

  useEffect(() => {
    setIsFilterDisabled(filterTypeMapping[type].checkFilterValues(options, products.count));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);

  const filterTitleClassnames = cn('filter__header', {
    'filter__header--disabled': isFilterDisabled,
  });

  return (
    <>
      <button type="button" onClick={onClickHandler} className={filterTitleClassnames}>
        <Icon
          fill={isFilterDisabled ? '#000' : '#000'}
          name={isOpened ? 'filter__opened' : 'filter__closed'}
        />
        {name}
      </button>
      {!isFilterDisabled && isOpened && <div>{filterTypeMapping[type].getComponent()}</div>}
    </>
  );
};

export default Filter;
