import { createSlice } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';

const getBasketSizeAndPriceValues = (items) => {
  const size = items.reduce((acc, item) => acc + item.amount, 0);
  const price = items.reduce((acc, item) => acc + item.amount * item.price, 0);
  return { size, price };
};

export const initialPosition = { id: 1, code: 'msk', name: 'Москва' };

const slice = createSlice({
  name: 'user',
  initialState: {
    isUserEmpty: true,
    isUserAboveTheAgeOf18: false,
    isLoading: false,
    basket: { items: [], checkedButtons: [], size: 0, price: 0 },
    isScroll: false,
    scrollPosition: 0,
    remoteAddress: '',
    isError: false,
    token: null,
    position: initialPosition,
  },
  reducers: {
    changeUserStatus(state, { payload: { isUserAboveTheAgeOf18 } }) {
      state.isUserAboveTheAgeOf18 = isUserAboveTheAgeOf18;
    },
    setIsScroll(state, { payload }) {
      state.isScroll = payload;
    },
    setScrollPosition(state, { payload }) {
      state.scrollPosition = payload;
    },
    setRemoteAddress(state, { payload: remoteAddress }) {
      return { ...state, remoteAddress };
    },
    updateCity(state, { payload: position }) {
      Cookies.set('city', position.code);
      localStorage.setItem('position', JSON.stringify(position));
      state.position = position;
    },
    updateUser(state) {
      const { items = [], size = 0, price = 0 } = JSON.parse(localStorage.getItem('basket')) || [];
      const position = JSON.parse(localStorage.getItem('position')) || initialPosition;
      Cookies.set('city', position.code);
      state.isUserEmpty = false;
      state.basket.items = items;
      state.basket.size = size;
      state.basket.price = price;
      state.isUserAboveTheAgeOf18 = !!Cookies.get('isUserAboveTheAgeOf18');
      state.position = position;
    },
    addItemToBasket(state, { payload: offer }) {
      if (state.basket.items.some((item) => item.id === offer.id)) {
        state.basket.items = state.basket.items.map((item) =>
          item.id === offer.id ? { ...item, amount: item.amount + offer.amount } : item
        );
      } else {
        state.basket.items.push({ ...offer });
      }
      const { size, price } = getBasketSizeAndPriceValues(state.basket.items);
      state.basket = { ...state.basket, size, price };
      localStorage.setItem('basket', JSON.stringify(state.basket));
    },
    updateItemAmountInBasket(state, { payload: { id, amount } }) {
      state.basket.items = state.basket.items.map((item) =>
        item.id === id ? { ...item, amount: Math.max(amount, 0) } : item
      );
      const { size, price } = getBasketSizeAndPriceValues(state.basket.items);
      state.basket = { ...state.basket, size, price };
      localStorage.setItem('basket', JSON.stringify(state.basket));
    },
    removeCheckedItems(state) {
      state.basket.items = state.basket.items.filter(
        (item) => !state.basket.checkedButtons.includes(item.id)
      );
      state.basket.checkedButtons = [];
      const { size, price } = getBasketSizeAndPriceValues(state.basket.items);
      state.basket = { ...state.basket, size, price };
      localStorage.setItem('basket', JSON.stringify(state.basket));
    },
    resetBasket(state) {
      state.basket = { items: [], checkedButtons: [], size: 0, price: 0 };
      localStorage.setItem('basket', JSON.stringify(state.basket));
    },
    setCheckedButtons(state, { payload }) {
      state.basket.checkedButtons = payload;
    },
  },
});

export const actions = { ...slice.actions };
export const asyncActions = {};

export default slice.reducer;
