import OptionsFilter from '../OptionsFilter';
import RadioFilter from '../RadioFilter';
import RangeFilter from '../RangeFilter';

export const FiltersTypes = {
  singleSelect: 'singleSelect',
  range: 'range',
  options: 'options',
};

export const filterTypeMapping = {
  [FiltersTypes.range]: {
    updateState: (values, setState) => {
      values.forEach((value) => {
        setState((state) => ({
          ...state,
          ...value,
        }));
      });
    },
    getComponent: () => <RangeFilter />,
    checkFilterValues: ({ min, max }) => min === null || max === null || min > max,
    checkIfFilterWasApplied: (currentQuery, filterName) =>
      currentQuery[`${filterName}_min`] || currentQuery[`${filterName}_max`],
  },
  [FiltersTypes.options]: {
    updateState: (value, setState) => {
      setState((state) => ({
        ...state,
        ...value,
      }));
    },
    getComponent: () => <OptionsFilter />,
    checkFilterValues: (options) => (!options ? true : options?.length === 0),
    checkIfFilterWasApplied: (currentQuery, filterName) => !!currentQuery[filterName],
  },
  [FiltersTypes.singleSelect]: {
    updateState: (value, setState) => {
      setState((state) => ({
        ...state,
        ...value,
      }));
    },
    getComponent: () => <RadioFilter />,
    checkFilterValues: (options) => (!options ? true : options?.length === 0),
    checkIfFilterWasApplied: (currentQuery, filterName) => !!currentQuery[filterName],
  },
};
