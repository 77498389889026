const pageMapping = {
  supplier: {
    standartFilters: ({ filterName }) =>
      ['volume', 'alco', 'price', 'country', 'region', 'type'].includes(filterName),
    subtypes: () => false,
    wineFilters: () => false,
  },
  brands: {
    standartFilters: () => true,
    subtypes: () => false,
    wineFilters: (_, { type }) => type === 'wine' || type === 'champagne',
  },
  default: {
    standartFilters: () => true,
    wineFilters: () => false,
    subtypes: () => false,
  },
  types: {
    standartFilters: ({ filterName }) => filterName !== 'type',
    subtypes: () => true,
    wineFilters: (_, { value }) => value === 'wine' || value === 'champagne',
  },
  tags: {
    standartFilters: ({ filterName }) => filterName !== 'type',
    subtypes: () => true,
    wineFilters: (_, { value }) => value === 'wine' || value === 'champagne',
  },
  search: {
    standartFilters: ({ filterName }) =>
      ['volume', 'price', 'alco', 'country'].includes(filterName),
    subtypes: () => false,
    wineFilters: () => false,
  },
};

export const getFilters = (position, filters) => (filterName) =>
  pageMapping[position.name]
    ? pageMapping[position.name][filters](filterName, position)
    : pageMapping.default[filters]();
