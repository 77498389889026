import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'flag-icon-css/sass/flag-icon.scss';
import 'nprogress/nprogress.css';
import '../styles/styles.scss';
import 'swiper/swiper.min.css';
import 'swiper/components/scrollbar/scrollbar.min.css';
import 'lazysizes';

import { useRouter } from 'next/router';
import NProgress from 'nprogress';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { wrapper } from '../redux/index';
import { actions as userActions } from '../redux/user';

NProgress.configure({ showSpinner: false });

const App = ({ Component, pageProps }) => {
  const dispatch = useDispatch();

  const router = useRouter();

  useEffect(() => {
    const handleStart = () => NProgress.start();
    const handleStop = () => NProgress.done();
    const popstateHandler = () => dispatch(userActions.setIsScroll(true));

    router.events.on('routeChangeStart', handleStart);
    router.events.on('routeChangeComplete', handleStop);
    router.events.on('routeChangeError', handleStop);
    window.addEventListener('popstate', popstateHandler);

    return () => {
      router.events.off('routeChangeStart', handleStart);
      router.events.off('routeChangeComplete', handleStop);
      router.events.off('routeChangeError', handleStop);
      window.removeEventListener('popstate', popstateHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router]);

  return <Component {...pageProps} />;
};

export default wrapper.withRedux(App);
