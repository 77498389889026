import { FiltersTypes } from '.';

export const standartFilters = [
  {
    type: FiltersTypes.range,
    name: 'Цена',
    filterName: 'price',
    getOptions: (currentFilters) => ({
      min: currentFilters.price_min,
      max: currentFilters.price_max,
    }),
  },
  {
    type: FiltersTypes.options,
    name: 'Объем',
    filterName: 'volume',
    getOptions: (currentFilters) => currentFilters.volumes,
  },
  {
    type: FiltersTypes.options,
    name: 'Крепость',
    filterName: 'alco',
    getOptions: (currentFilters) => currentFilters.alcos,
  },
  {
    type: FiltersTypes.options,
    name: 'Страна',
    filterName: 'country',
    getOptions: (currentFilters) => currentFilters.countries,
  },
  {
    type: FiltersTypes.options,
    name: 'Регион производителя',
    filterName: 'region',
    getOptions: (currentFilters) => currentFilters.regions,
  },
  {
    type: FiltersTypes.options,
    name: 'Поставщики',
    filterName: 'supplier',
    getOptions: (currentFilters) => currentFilters.suppliers,
  },
  {
    type: FiltersTypes.options,
    name: 'Бренды',
    filterName: 'brand',
    getOptions: (currentFilters) => currentFilters.brands,
  },
  {
    type: FiltersTypes.options,
    name: 'Производители',
    filterName: 'winery',
    getOptions: (currentFilters) => currentFilters.wineries,
  },
  {
    type: FiltersTypes.singleSelect,
    name: 'Виды алкоголя',
    filterName: 'type',
    getOptions: (currentFilters) => currentFilters.types,
  },
];
