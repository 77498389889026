import cn from 'classnames';

import { svgIcons } from './svgIcons';

const Icon = ({ name, fill, onClick, className, width, height }) => {
  const svg = svgIcons[name];

  const iconClasses = cn(className, {
    'icon--active': !!onClick,
  });

  return svg ? (
    <span className={iconClasses} onClick={onClick}>
      {width && height ? svg(fill, width, height) : svg(fill)}
    </span>
  ) : null;
};

export default Icon;
